@import '../../../assets/scss/variables.scss';
.numberSection{
    padding-top: 96px;
    @include max-screen($tablet) {
        padding-top:30px;
     }
    ul{
        gap: 36px;
        @include max-screen($tablet) {
            gap: 16px;
         }
        li{
            max-width: 290px;
            width: calc(25% - 27px);
            text-align: center;
            @include max-screen($tablet) {
               width: calc(50% - 8px);
               max-width: 85%;
               margin: 0 auto;

            }
            @include max-screen($mobile) {
                width: 100%;
                max-width: 100%;
               
             }
            h2{
                font-size: 72px;
                @include max-screen($tablet) {
                    font-size: 42px;
                 }
                 @include max-screen($mobile) {
                    font-size: 32px;
                 }
            }
            h5{
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 16px;
                @include max-screen($tablet) {
                    margin-bottom: 0;
                    font-size: 16px;
                 }
            }
            p{
                font-size: 18px;
                @include max-screen($mobile) {
                    font-size: 14px;
                 }
            }
        }
    }
}