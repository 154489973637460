@import '../../assets/scss/variables.scss';

.main {
    background: var(--color-white);
    padding-bottom: 93px;
    @include max-screen($tablet) {
        padding-bottom: 30px;
    }
    &__service {
       
        padding: 64px 0;

        @include max-screen($tablet) {
            padding: 30px 0;
        }
    }

    &__choose {
        background: $brand-bg-gradient;
        padding: 64px 0;

        @include max-screen($tablet) {
            padding: 30px 0;
        }
    }

    header {
        text-align: center;
        margin-bottom: 48px;

        @include max-screen($tablet) {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 36px;

            @include max-screen($tablet) {
                font-size: 26px;
            }
        }

        p {
            margin: 16px auto 0;
            font-size: 20px;
            max-width: 810px;

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
    }



    &__table {
        max-width: 1200px;
        margin: 64px auto 0;

        @include max-screen($tablet) {
            margin: 30px auto 0;
        }
    }



    &__testimonial {
        max-width: 1200px;
        margin: 47px auto 0;
        gap: 48px;

        @include max-screen($tablet) {
            margin: 30px auto 0;
        }
    }

    &__testimonialStar {
        gap: 5px;
        margin-bottom: 10px;

        svg {
            color: $color-yellow-dark;
            fill: $color-yellow-dark;
        }
    }

    &__testimonialCardInfo {
        gap: 16px;
        margin-top: 24px;

        h3 {
            font-size: 16px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
            color: var(--color-gray-600);
        }
    }

    &__testimonialCard {
        background-color: var(--color-white);
        border-radius: 12px;
        padding: 32px;
        width: calc(50% - 24px);
        transition-duration: .5s;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 4px 6px -4px rgba($color-black, 0.102), 0px 10px 15px -3px rgba($color-black, 0.102);
        display: flex;
        flex-direction: column;


        @include max-screen($tablet) {
            width: 100%;
            padding: 16px;
        }

        @include max-screen($mobile) {
            width: 100%;
        }

        figure {

            img {
                object-fit: cover;
                @include round-image(96px);
            }

        }

        &>p {
            font-size: 20px;
            color: var(--color-primary);
            font-style: italic;
            flex: 1;
            @include max-screen($tablet) {
                font-size: 16px;
            }
        }

        span {
            position: absolute;
            right: 24px;
            top: 24px;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 3px 8px;
            border-radius: 55px;
            font-size: 12px;
            font-weight: 700;
            font-family: $font-poppins;
        }
    }
}