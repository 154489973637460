.card{
    border: 1px solid var(--color-border);
    border-radius: 10px;
    img{
        max-width: 100%;
        aspect-ratio: 4/2;
        border-radius: 10px 10px 0 0;
       
    }
    &__info{
        padding: 20px;
    }
    &__link{
        margin-top: 10px;
        color: var(--color-primary);
        display: inline-flex;
    }
}