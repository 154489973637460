@import '../../assets/scss/variables.scss';

.createList {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 15px;

        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
        }

        .flag {
            border-radius: 10px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            justify-content: center;
            align-items: center;

        }
    }

    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;

            span {
                font-size: 12px;
                font-weight: 400;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }

    .switch {
        gap: 8px;
        margin-bottom: 16px;

        h5 {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }

    &__action {
        margin-top: 32px;
    }
    &__error{
        margin-top:15px;
    }

    .step2Button {
        @include max-screen($mobile) {
            margin-top: 15px;
        }
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--color-button);
    }

    .step2 {
        :global {
            .inputSelect {
                margin-bottom: 10px;
            }
        }
    }

    .recordsSkip {
        gap: 15px;
        margin-bottom: 10px;

        p {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
        }

        h5 {
            font-size: 20px;
            color: var(--color-button);
            font-weight: 500;
        }

        &.total {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid var(--color-border);

            h5 {
                font-weight: 700;
            }
        }
    }
    .slider{
        margin-bottom: 16px;
        p{
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
            margin-bottom: 5px;
        } 
    }
    .loader{
        position: relative;
    }
.note{
    p{
        font-size: 12px;
        color: var(--color-button);
        &:not(:last-child){
            margin-bottom: 10px;
        }
    }
}
.billingCard{
    margin-top: 16px;
    border-radius: 12px;
    padding: 16px;
    box-shadow: $shadow;
    border: 1px solid var(--color-border);
    &.blueCard{
        box-shadow: 0 0 0;
        border-radius: 0;
        border: 0;
        border-top: 1px solid var(--color-border);

        .info{
            svg{
                color:var(--color-primary);
            }
            &::after, &::before{
                border: 2px solid var(--color-primary);
            }
        }
    }
   
    &__inner{
        gap: 16px;
        .billingInfo{
            width: 100%;
           }
        .info{
            position: relative;
            display: flex;
            justify-content: center;
             svg{
                 color:var(--color-error);
                
             }
             &::after, &::before{
                 content: "";
                 width: 28px;
                 height: 28px;
                 border: 2px solid var(--color-error);
                 border-radius: 24px;
                 position: absolute;
                 opacity: 0.3;
                 left: -6px;
                 top: -6px;
                 animation: waves 2.5s linear 2.9s forwards;
                 animation-delay: 0;
                 animation-iteration-count: infinite;
                 animation-direction: forward;
                
             }
             &::before{
                 width: 40px;
                 height: 40px;
                 opacity: 0.1;
                 left: -12px;
                 top: -12px;
                 animation: waves2 2.5s linear 2.9s forwards;
                 animation-delay: 0;
                 animation-iteration-count: infinite;
                 animation-direction: forward;
             }
             @keyframes waves {
                 0% {
                   transform: scale(0);
                   opacity: 0;
                 }
                 
                 100% {
                   transform: scale(1);
                   opacity: 0.3;
                 }
               }
               @keyframes waves2 {
                 0% {
                   transform: scale(0);
                   opacity: 0;
                 }
                 
                 100% {
                   transform: scale(1);
                   opacity: 0.1;
                 }
               }
         }
        h5{
            font-size: 14px;
            color: var(--color-button);
            font-weight: 600;
            margin-bottom: 4px;
        }
        p{

            font-size: 14px;
            color: var(--color-textLight);
            margin-bottom: 12px;
        }
        span{
            font-size: 14px;
            color: var(--color-blue);
            display: table;
            font-weight: 600; 
            &:hover{
                text-decoration: underline;
            }
        }
        .close{
            color: var(--color-grayLight);
        }
    }
}
  

    .createListCreated {
        text-align: center;
        position: relative;
        &.noAnimations{
            &:after {
                display: none;
            }
        :global .button__text {
                font-size: 16px;
            }
        }

        &:after {
            content: "";
            @include background-image(confetti, gif, center center, no-repeat, cover);
            position: absolute;
            width: 160px;
            height: 160px;
            top: -50px;
            left: 0;
            margin: 0 auto;
            right: 0;
            z-index: 2;
        }


        figure {
            border-radius: 28px;
            border: 8px solid var(--color-greenBg);
            background: var(--color-greenLight);
            display: flex;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            margin: 0 auto 16px;
            color: var(--color-greenDark);
            position: relative;
            z-index: 1;

        }
    }
    


}
.skipTrace{
    gap: 5px;

}
.skipTraceSwitch{
    @include max-screen($mobile) {
       flex-wrap: wrap;
       gap: 15px !important;
     }
}
.subtotalSpace{
    padding-top: 10px;
}