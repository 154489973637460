@import '../../assets/scss/variables.scss';

.detail {
    padding: 0 40px;
    gap: 46px;

    @include max-screen($tablet) {
        gap: 0;
        padding: 0 15px;
    }

    &__left {
        width: calc(100% - 515px);

        @include max-screen($tablet) {
            width: 100%;

        }
    }

    &__right {
        width: 469px;

        @include max-screen($tablet) {
            width: 100%;

        }
    }
}

.cardSlider {
    padding: 0 0px 15px 4px;

    :global {
        .slick-slide {
            padding-right: 40px;
            margin-bottom: 15px;
        }
    }
}

.card {
    border-radius: 8px;
    background: var(--color-white);
    box-shadow: $shadow3;
    padding: 32px;
    margin-bottom: 30px;

    h2 {
        font-size: 32px;
        font-weight: 500;
        color: var(--color-heading);

        @include max-screen($desktop-xlarge) {
            font-size: 26px;
        }

        span {
            font-size: 16px;
            color: var(--color-grayLight);
            padding-left: 20px;
        }
    }

    p {
        font-size: 16px;
        color: var(--color-grayLight);

        @include max-screen($desktop-xlarge) {
            font-size: 14px;
        }

        span {
            color: var(--color-primary);

            &.green {
                color: var(--color-green);
            }
        }
    }

    .headerArrow {
        max-width: 910px;
        margin-bottom: 24px;
    }
}

.callActivity {
    text-align: center;

    h4 {
        font-size: 28px;
        font-weight: 500;
        color: var(--color-heading);
    }

    p {
        font-size: 16px;
        color: var(--color-grayLight);
    }

    .call {
        width: 50%;
        border-right: 1px solid var(--color-border);
    }

    .leads {
        width: 50%;
    }
}

.activity {
    margin-top: 24px;

    ul {
        position: relative;
        padding-left: 45px;

        &::after {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            left: 11px;
            top: 0;
            background-color: var(--color-border);
            z-index: 1;
        }

        li {
            margin-bottom: 32px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: -45px;
                top: 0;
                background-color: var(--color-white);
                border: 2px solid #3A57E8;
                @include round-image(21px);
                z-index: 2;
            }

            h5 {
                font-size: 19px;
                font-weight: 400;
                color: var(--color-heading);

                @include max-screen($desktop-xlarge) {
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
                color: var(--color-grayLight);

                @include max-screen($desktop-xlarge) {
                    font-size: 14px;
                }
            }
        }
    }
}

// new page style start here

.progress {
    margin-bottom: 32px;

    @include max-screen($mobile) {
        margin-bottom: 15px;
    }

    &__bar {
        width: calc(100% - 416px);
        padding-right: 44px;

        @include max-screen($mobile) {
            width: 100%;
            padding-right: 0;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
            margin-bottom: 10px;
        }
    }

    &__button {
        width: 416px;
        gap: 12px;
        justify-content: flex-end;

        @include max-screen($mobile) {
            width: 100%;
            margin-top: 15px;
        }
        :global{
            .filter-icon{
                min-width: 108px;
               .button__text{
                & + .button__icon{
                    position: absolute;
                    left: 23px;
                    top: 5px;
                    svg{
                        width: 15px;
                        height: 15px;
                    }
                }
               }
            }
          }
    }
}

.cards {
    gap: 24px;
    width: 100%;

    @include max-screen($desktop) {
        gap: 15px;
    }

    &__col {
        width: calc(25% - 18px);

        @include max-screen($desktop) {
            width: calc(50% - 8px);
        }

        @include max-screen($mobile) {
            width: 100%;
        }
    }
}

.callAndActivity {
    gap: 24px;
    margin-top: 24px;

    @include max-screen($desktop) {
        gap: 15px;
        margin-top: 15px;
    }

    &__call {
        width: calc(75% - 8px);
        border-radius: 12px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        box-shadow: $shadow;
        padding: 24px;

        @include max-screen($desktop) {
            width: 100%;
        }

        h3 {
            font-size: 16px;
            font-weight: 600;
            color: var(--color-text);
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }
    }

    &__activity {
        width: calc(25% - 16px);
        border-radius: 12px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        box-shadow: $shadow;
        padding: 24px;

        @include max-screen($desktop) {
            width: 100%;
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }
    }
}

.callInfo {
    margin-top: 20px;
    overflow-y: auto;
    max-height: 270px;

    ul {
        li {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 32px;

            figure {
                color: var(--color-primary);
                position: relative;

                &:after {
                    content: "";
                    width: 2px;
                    height: 16px;
                    position: absolute;
                    background-color: var(--color-border);
                    left: 50%;
                    bottom: calc(-50% - 8px);
                }
            }

            figcaption {
                h5 {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--color-button);

                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: var(--color-textLight);
                    }
                }

                p {
                    font-size: 14px;
                    color: var(--color-textLight);
                }
            }

            &:last-child {
                margin-bottom: 0;

                figure {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.lead {
    // border-radius: 12px;
    // border: 1px solid var(--color-border);
    // background: var(--color-white);
    // box-shadow: $shadow;
    // padding:  0;
    // margin-top: 24px;

    &__header {
        gap: 15px;
        padding: 24px 24px 0;
      

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            gap: 8px;
            margin-bottom: 5px;
        }

        .pill {
            border-radius: 6px;
            border: 1px solid var(--color-border);
            background: var(--color-white);
            box-shadow: $shadow;
            padding: 2px 6px;
            font-size: 12px;
            font-weight: 500;
            color: var(--color-button);
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }

        .buttonRight {
            gap: 12px;
        }
    }

    &__filters {
        padding: 12px 16px;
        margin-top: 20px;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        gap: 5px;

        .filtersTabs {
            width: 165px;

            @include max-screen($mobile) {
                width: 100%;
                justify-content: center;
            }

            ul {
                border-radius: 8px;
                border: 1px solid var(--color-borderDark);
                box-shadow: $shadow;
                display: flex;
                align-items: center;
                overflow: hidden;
                height: 44px;
                @include max-screen($mobile) {
                    justify-content: center;
                }

                li {
                    &:not(:last-child) {
                        border-right: 1px solid var(--color-borderDark);
                    }
                    @include max-screen($mobile) {
                      width: 50%;
                      text-align: center;
                    }
                    span {
                        padding: 11px 16px;
                        display: block;
                        font-weight: 600;
                        color: var(--color-button);
                        font-size: 14px;
                        background-color: var(--color-white);

                        &.active,
                        &:hover {
                            background-color: var(--color-active);
                        }
                    }
                }
            }

        }

        .search {
            width: 100%;

        }
    }

    &__list {
        // max-height: 529px;
        overflow-y: auto;

        table {
            width: 100%;
            text-align: left;
            border-collapse: collapse;

            tr {
                th {
                    background-color: var(--color-bgLight);
                    padding: 12px 24px;
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--color-textLight);
                    position: sticky;
                    z-index: 2;
                    top: 0;

                    &:last-child {
                        width: 170px;
                    }
                }

                td {
                    padding: 12px 24px;
                    font-size: 14px;
                    color: var(--color-textLight);
                    border-top: 1px solid var(--color-border);

                    .leadInfo {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        figure {
                            color: var(--color-primary);
                        }

                        figcaption {
                            h5 {
                                font-size: 14px;
                                font-weight: 500;
                                color: var(--color-text);

                                span {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: var(--color-textLight);
                                }
                            }

                            p {
                                font-size: 14px;
                                color: var(--color-textLight);
                            }
                        }
                    }
                }
                &:nth-child(even) {
                    td {
                        background-color: var(--color-active);
                    }
                }


            }
        }
    }
 
}

.cancelDialog {
    &__icon {
        display: flex;
        @include round-image(48px);
        justify-content: center;
        align-items: center;
        border: 8px solid var(--color-errorBg);
        background: var(--color-errorBorder);
        color: var(--color-error);
    }

    &__info {
        margin-top: 16px;

        h2 {
            font-size: 18px;
            color: var(--color-text);
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            color: var(--color-textLight);
        }
    }

    &__footer {
        margin-top: 32px;
        gap: 12px;
    }

}

.tableShorting{
    gap: 6px;
}
.shortIcon{
    width: 16px;
    height: 16px;
    color: var(--color-textLight);
    svg{
        width: 16px;
        height: 16px;  
    }
}
.leadsTab{
    border-radius: 12px;
    border: 1px solid var(--color-border);
    background: var(--color-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 0;
    margin: 24px 0 0;
    :global {
        .MuiTabs-root {
            border-bottom: 1px solid var(--color-border);
            background: var(--color-white);
            border-radius: 12px 12px 0 0;
            .MuiTabs-scroller {
                max-width: 1920px;
                margin: 0 auto;
                padding: 0 60px;
                @include max-screen($desktop) {
                    padding: 0 35px;
                }
                @include max-screen($tablet) {
                    padding: 0 15px;
                }
            }

            .MuiTabs-flexContainer {
                width: 100%;
                justify-content: space-between;
                gap: 12px;

                .MuiButtonBase-root {
                    font-weight: 600;
                    font-size: 14px;
                    // width: calc(14.28% - 12px);
                    font-family: $font-family-base;
                    color: var(--color-gray);
                    letter-spacing: 0;
                    text-transform: capitalize;
                    padding: 0 4px;
                    white-space: nowrap;
                    @include max-screen($desktop) {
                        width: auto;
                    }
                    @include max-screen($tablet) {
                        width: auto;
                    }

                    &.Mui-selected {
                        color: var(--color-primary);
                        span {
                        span {
                            color: var(--color-blue);
                            background-color: var(--color-blueLight);
                        }
                    }
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
            }
        
        }
    }
}