@import '../../assets/scss/variables.scss';

.main {

    background: $brand-bg-gradient;
    padding: 64px 0;
    @include max-screen($tablet) {
        padding: 30px 0;
       }
    header {
        text-align: center;
        margin-bottom: 48px;
        @include max-screen($tablet) {
            margin-bottom: 30px;
           }
        h2 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 26px;
               }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 720px;
            @include max-screen($tablet) {
                font-size: 16px;
               }
        }
    }



    &__chooseRow{
        max-width: 1200px;
        margin: 47px auto;
        gap: 24px;
        @include max-screen($tablet) {
            margin: 30px auto;
           }
    }
    &__chooseCard{
        background-color: var(--color-white);
        padding: 24px;
        width: calc(25% - 18px);
        position: relative;
        overflow: hidden;
        border: 1px solid var(--color-border);
        @include max-screen($tablet) {
            width: calc(50% - 12px);
           }
           @include max-screen($mobile) {
            width: 100%;
           }
        figure {
       @include round-image(48px);
            background-color: $color-blueBorder;
            margin-bottom: 16px;
            color: rgba($color-primary, 0.95);
            svg {
                
                width: 26px;
              height: 26px;
            }
          
        }

        h3 {
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }
    }

}