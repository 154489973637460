@import '../../assets/scss/variables.scss';

.navRight {
    gap: 16px;

    @include max-screen($desktop) {
        gap: 8px;
    }

    li {

        @include max-screen($tablet) {
            width: 100%;
        }
        &.hasDropdown {
            position: relative;
            display: flex;
            gap: 4px;
            align-items: center;
            @include max-screen($tablet) {
                width: 100%;
                text-align: center;
                padding: 0;
                justify-content: space-between;
                flex-wrap: wrap;

               
            }
            &:hover {
                ul {
                    display: block;
                }
            }
        }

        a {
            color: var(--color-primary);
            font-size: 16px;
            text-decoration: none;
            font-weight: 500;
            font-family: $font-poppins;

            &:hover {
                background: $brand-gradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        :global .button {
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            font-family: $font-poppins;
        }

        .setting {
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--color-white);
            border-radius: 6px;
            color: var(--color-gray);

            &:hover,
            &.active {
                color: var(--color-primary);
                background-color: var(--color-white);
            }

        }

        .profile {
            font-size: 14px;
            font-weight: 600;
            gap: 6px;
            display: flex;
            align-items: center;

        }

        button {
            @include max-screen($tablet) {
                width: 100%;
            }
        }

        &.hideMobile {
            @include max-screen($tablet) {
                display: none;
            }
        }

        &.firstNav {
            margin-right: 8px;

            @include max-screen($tablet) {
                margin-right: 0;
            }
        }
    }

    &.navRightNotLogIn {
        gap: 20px;
    }

}