@import '../../assets/scss/variables.scss';

.target {
    background-color: var(--color-gray-50);
    padding: 96px 0;

    @include max-screen($tablet) {
        padding: 30px 0;
    }

    header {
        text-align: center;

        margin: 0 auto 48px;

        @include max-screen($tablet) {
            margin: 0 auto 30px;
        }

        h2 {
            font-size: 36px;

            @include max-screen($tablet) {
                font-size: 26px;
            }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 768px;

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
    }

    &__catCard {
        width: calc(25% - 18px);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
        border-radius: 12px;
        background-color: var(--color-white);
        overflow: hidden;
        transition-duration: .3s;

        @include max-screen($tablet) {
            width: calc(50% - 12px);
        }

        @include max-screen($mobile) {
            width: 100%;
        }

        img {
            max-width: 100%;
        }

        &>figure {
            height: 192px;
            position: relative;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            figcaption {
                img {
                    object-fit: cover;
                    @include round-image(40px);
                }
            }
        }

        article {
            padding: 20px 24px;

            figure {
                display: inline-flex;
            }

            h3 {
                font-size: 18px;
                font-weight: 500;
            }

            p {
                margin-top: 8px;
                font-size: 16px;
            }
        }
    }

    &__cat {
        gap: 24px;
        max-width: 1216px;
        margin: 0 auto;
    }

    &__text {
        text-align: center;
        margin-top: 64px;

        p {
            font-size: 20px;
            font-weight: 500;
        }
    }
}