@import '../../assets/scss/variables.scss';

.main {

    &__service {
        background: var(--color-white);
        padding: 64px 0;

        @include max-screen($tablet) {
            padding: 30px 0;
        }
    }

    &__choose {
        background: $brand-bg-gradient;
        padding: 64px 0;

        @include max-screen($tablet) {
            padding: 30px 0;
        }
    }

    header {
        text-align: center;
        margin-bottom: 48px;

        @include max-screen($tablet) {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 36px;

            @include max-screen($tablet) {
                font-size: 26px;
            }
        }

        p {
            margin: 16px auto 0;
            font-size: 20px;
            max-width: 810px;

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
    }



    &__feature {
        max-width: 1200px;
        margin: 47px auto 0;
        gap: 48px;

        @include max-screen($tablet) {
            margin: 30px auto 0;
            gap: 0;
        }
    }

    &__featureCard {
        gap: 16px;

        &:not(:last-child) {
            margin-bottom: 32px;
        }

        figure {
            width: 64px;
            height: 64px;
            min-width: 64px;
            border-radius: 8px;
            background: $brand-blue-gradient;
            margin-bottom: 6px;

            svg {
                width: 26px;
                height: 26px;
                color: var(--color-white);
            }

        }

        h3 {
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }

        span {
            position: absolute;
            right: 24px;
            top: 24px;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 3px 8px;
            border-radius: 55px;
            font-size: 12px;
            font-weight: 700;
            font-family: $font-poppins;
        }
    }

    &__chooseRow {
        max-width: 1200px;
        margin: 47px auto 0;
        gap: 24px;

        @include max-screen($tablet) {
            margin: 30px auto 0;
        }
    }

    &__chooseCard {
        background-color: var(--color-white);
        border-radius: 12px;
        padding: 20px 40px;
        width: calc(25% - 22px);
        transition-duration: .5s;
        position: relative;
        overflow: hidden;
        border: 1px solid var(--color-gray-100);
        box-shadow: 0px 4px 6px -1px rgba($color-black, 0.102);
        text-align: center;
        @include max-screen($desktop) {
            padding: 20px;
        }
        @include max-screen($tablet) {
            width: calc(50% - 12px);
            padding: 16px;
        }
        @include max-screen($mobile) {
            width: 100%;
        }
        figure {
            @include round-image(64px);
            margin: 0 auto;
            background-color: $color-blueBorder;
            margin-bottom: 8px;
            color: rgba($color-primary, 0.65);

            svg {

                width: 24px;
                height: 24px;
            }

        }

        h3 {
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 600;
            text-decoration: underline;
        }

        p {
            font-size: 16px;
        }

        span {
            position: absolute;
            right: 24px;
            top: 24px;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 3px 8px;
            border-radius: 55px;
            font-size: 12px;
            font-weight: 700;
            font-family: $font-poppins;
        }

        &:nth-child(2) {
            figure {
                background-color: rgba($color-magenta-bg, 0.3);
                color: rgba($color-magenta-text, 0.9);
            }
        }

        &:nth-child(3) {
            figure {
        
                background-color: $color-greenLight;
                color: $color-green-light;
            }
        }
        &:nth-child(4) {
            figure {
                background-color: $color-warningBg;
                color: $color-warningText;
            }
        }
    }

    &__steps {
        width: calc(50% - 24px);

        @include max-screen($tablet) {
            width: 100%;
        }
    }

    &__img {
        width: calc(50% - 24px);

        img {
            border-radius: 12px;
            box-shadow: $shadow12;
            max-width: 100%;
        }

        @include max-screen($tablet) {
            width: 100%;
            margin-top: 30px;
        }

    }
    &__btn{
        margin-top: 76px;
    }
}