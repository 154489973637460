@import '../../assets/scss/variables.scss';

.main {
    background-color: var(--color-gray-50);
    padding: 64px 0;
    @include max-screen($tablet) {
        padding: 30px 0;
       }
    header {
        text-align: center;
        margin-bottom: 48px;
        @include max-screen($tablet) {
            margin-bottom: 30px;
           }
        h2 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 26px;
               }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 670px;
            @include max-screen($tablet) {
                font-size: 16px;
               }
        }
    }

    &__panel {
        max-width: 1200px;
        margin: 60px auto 0;
        background-color: var(--color-primary);
        border-radius: 24px;
        padding: 24px;
        color: var(--color-white);
        position: relative;
        z-index: 1;
        overflow: hidden;
        @include max-screen($tablet) {
            margin: 30px auto 0;
           }
         &:after{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: $brand-blue-gradient;
            opacity: 0.95;
            z-index: -1;
         } 

    }

    &__panelRow {
        gap: 8px;
        width: 100%;
    }

    &__panelCard {
        width: calc(25% - 6px);
        @include max-screen($mobile) {
            width: calc(50% - 4px);
           }
          
    }

    &__panelCardBox {
        width: 100%;
        padding:20px 16px;
        gap: 16px;
        @include max-screen($tablet) {
            padding: 8px;
           }

        figure {
            width: 40px;
            height: 40px;
            min-width: 40px;
            background-color: rgba($color-white, 0.1);
            border-radius: 12px;
            svg{
                width: 18px;
                height: 18px;
            }
            
        }

        figcaption {
            gap: 5px;

        }

        h5 {
            font-size: 20px;
            color: var(--color-white)
        }

        p {
            font-size: 14px;
            color: var(--color-gray-200)
        }
    }

    &__feature {
        max-width: 1200px;
        margin: 64px auto 0;
        gap: 24px;
        @include max-screen($tablet) {
            margin: 30px auto 0;
           }
    }

    &__featureCard {
        background-color: var(--color-white);
        border-radius: 16px;
        padding: 24px;
        width: calc(33.3% - 16px);
        transition-duration: .5s;
        position: relative;
        overflow: hidden;
        @include max-screen($tablet) {
            width: calc(50% - 12px);
           }
           @include max-screen($mobile) {
            width: 100%;
           }



        figure {
            width: 48px;
            height: 48px;
            min-width: 48px;
            border-radius: 12px;
            background-color: rgba($color-primary, 0.05);
            margin-bottom: 16px;
            svg {
                color: var(--color-primary);
              
            }
        }

        h3 {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }
    }
}