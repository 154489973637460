@import '../../../assets/scss/variables.scss';
.contentSec{
    font-family: $font-size-base;
     &__row{
        gap: 32px;
       
        &:not(:last-child){
            padding-bottom: 96px;
            @include max-screen($tablet) {
                padding-bottom:30px;
             }
        }
       
        &:nth-child(odd){
            flex-direction: row-reverse;
        }
     }
     &__info{
        width: calc(40% - 16px);
        @include max-screen($tablet) {
           width: 100%;
         }
         @include max-screen($mobile) {
           text-align: center;
          }
        h2{
            font-size: 32px;
            @include max-screen($tablet) {
               font-size: 22px;
              }
        }
        p{
            font-size: 18px;
            margin-top: 24px;       
            color: var(--color-textLight);
            @include max-screen($tablet) {
                margin-top: 16px;
                font-size: 16px;
              }
              @include max-screen($mobile) {
                font-size: 14px;
                margin-top: 0;
               }
        }
        ul{
            margin-top: 32px;
            @include max-screen($tablet) {
                margin-top: 16px;
              }
            li{
                font-size: 16px;
                color: var(--color-textLight);
                display: flex;
                gap: 12px;
                margin-top: 16px;
                @include max-screen($mobile) {
                    font-size: 14px;
                    margin-top: 10px;
                    text-align: left;
                   }
                span{
                    background-color: var(--color-primary);
                    @include round-image(20px);
                    margin-top: 2px;
                    color: var(--color-white);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    @include max-screen($mobile) {
                        @include round-image(18px);
                       }
                }
            }
        }
        &.crmInfo{
            width: 385px;
            @include max-screen($tablet) {
               width: 100%;
             }
        }
     }
     &__img{
        width: calc(60% - 16px);
        overflow: hidden;
        @include max-screen($tablet) {
            width: 100%;
          }
          video{
            width: 100%;
            transform: scale(1.09);
         }
         &.crmImg{
            width: calc(100% - 417px);
            @include max-screen($tablet) {
               width: 100%;
             }
        }
     }
     &__link{
        margin-top: 32px;
        @include max-screen($tablet) {
            margin-top: 16px;
            justify-content: center;
          }
     }
     
}