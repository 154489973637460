@import '../../assets/scss/variables.scss';

.main {
    padding: 96px 0;
    @include max-screen($tablet) {
        padding: 30px 0;
    }
    header {
        text-align: center;

        h2 {
            font-size: 36px;
            font-weight: 600;
            letter-spacing: -0.2px;
            margin: 0;

            @include max-screen($tablet) {
                font-size: 22px;
            }
        }

        p {
            margin-top: 20px;
            font-size: 20px;
            color: var(--color-primary);
            @include max-screen($tablet) {
                font-size: 16px;
                margin-top: 5px;
            }
        }
    }


    .accordion {
        margin: 64px auto 0;
        max-width: 768px;
        @include max-screen($tablet) {
            margin: 30px auto 0;
        }
        :global {
            .MuiPaper-root {
                box-shadow:  0 0 0;
               margin: 0;
            }
            .MuiButtonBase-root{
                padding: 0;
                gap: 16px;
                min-height: 0;
            }
            .MuiAccordionSummary-content{
                margin: 24px 0;
                font-size: 18px;
                font-weight: 500;
                min-height: 0;
                color: var(--color-primary);
                font-family: $font-poppins;
                @include max-screen($mobile) {
                    font-size: 16px;
                    margin: 10px 0;
                 }
            }
            .MuiAccordionDetails-root {
                padding: 0 0 24px;
                color: var(--color-gray-600);
                h3, p{
                    font-size: 16px;
                    @include max-screen($mobile) {
                        font-size: 14px;
                     }
                }
                h3{
                    font-weight: 500;
                    margin-bottom: 5px;
                }
            }
            .minus-icon{
                display: none;
            }
            .plus-icon{
                display: inline-flex;
            }
            .Mui-expanded{
                
                .MuiAccordionSummary-content{
                    font-weight: 700;
                   
                    margin: 8px 0;
                }
                .plus-icon{
                    display: none;
                }
                .minus-icon{
                    display: inline-flex;
                }
            }
           .MuiAccordionSummary-expandIconWrapper{
            transform: none;
            color: var(--color-primary);
           }
        }
    }
}