@import '../../assets/scss/variables.scss';

.search {

&__header{
    background-color: var(--color-white);
}
&__section{
    padding: 96px 0;
    background: $brand-bg-gradient;
    @include max-screen($tablet) {
        padding:30px 0;
      }
}
&__video{
    video{
        width: 100%;
        display: block;
    }

}
    header {
        text-align: center;
        padding-bottom: 25px;
        @include max-screen($tablet) {
            padding-bottom:15px;
          }
        h2 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 26px;
              }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 720px;
            @include max-screen($tablet) {
                font-size: 16px;
              }
        }
    }

    &__row {
        max-width: 1152px;
        margin: 0 auto;
        gap: 48px;
        @include max-screen($tablet) {
            gap: 16px;
           }
    }
    
    &__box {
        width: calc(50% - 24px);
        box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.102), 0px 4px 6px -1px rgba(0, 0, 0, 0.102);
        backdrop-filter: blur(4px);
        padding: 32px;
        border-radius: 12px;
        background-color: var(--color-white);
        font-family: $font-poppins;
        flex-direction: column;
        display: flex;
        @include max-screen($tablet) {
           width: 100%;
           padding:16px;
          }
        ul {
            flex: 1;
            li {
                font-size: 16px;
                gap: 12px;
                color: var(--color-gray-600);
                &:not(:last-child) {
                    margin-bottom: 18px;
                }

                span {
                    background-color: var(--color-primary);
                    @include round-image(24px);
                    color: var(--color-white);
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
    }

    &__boxHeader {
        gap: 16px;
        margin-bottom: 16px;

        span {
            @include round-image(48px);
            color: var(--color-primary);
            background-color: rgba($color-primary, 0.1);
            @include max-screen($tablet) {
                @include round-image(36px);
               }
               svg{
                @include max-screen($tablet) {
                   width: 20px;
                   }
               }
        }

        h3 {
            font-size: 24px;
            @include max-screen($tablet) {
                font-size: 20px;
               }
        }
    }
    &__eg{
        background-color: var(--color-active);
        gap: 8px;
        padding: 12px;
        margin-top: 20px;
        font-size: 14px;
        color: var(--color-gray-600);
        border: 1px solid rgba($color-border, 0.5);
        border-radius: 8px;
    }
    &__footer{
        margin-top: 48px;
        text-align: center;
        @include max-screen($tablet) {
            margin-top:24px;
           }
        p{
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 32px;
            color: var(--color-primary);
            font-style: italic;
            @include max-screen($tablet) {
                font-size: 16px;
                margin-bottom: 16px;
               }
            
        }
    }
  
}