@import '../../../assets/scss/variables.scss';

.card {
    width: 241px;
    background-color: var(--color-white);
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    font-family: $font-poppins;
    transition: all 0.2s ;
    border: 2px solid transparent;
    &__info {
        padding: 12px;

        h5 {
            font-size: 18px;

            span {
                font-size: 12px;
                color: var(--color-gray-600);
                font-weight: 400;
            }
        }

        p,
        address {
            margin-top: 5px;
            font-size: 12px;
            color: var(--color-gray-600);
            font-style: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__bottom {
        font-size: 10px;
        color: var(--color-gray);
        padding-top: 10px;
    }

    &:hover {
        box-shadow: $shadow12;
        background: $brand-gradient padding-box, $brand-gradient border-box;
        border: 2px solid transparent;
 
        .card__imgArrows{
            opacity: 1;
        }
    }

    &__content {
        position: relative;
        background-color: var(--color-white);
        border-radius: 10px;
    }
&__tag{
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 12px;
    font-weight: 500;
    font-family: $font-poppins;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 3px 8px;
    height: 24px;
    border-radius: 55px;
    z-index: 9;
    &.status1 {
        background-color: $color-gray-200;
        color: $color-gray-600;
    }
    &.status2 {
        background-color:$color-yellow-bg;
        color: $color-yellow-text;
    }
    &.status3 {
        background-color: $color-errorBg;
        color: $color-error;
    }
    &.status4 {
        background-color: $color-magenta-bg;
        color: $color-magenta-text;
    }
    &.status5 {
        background-color: $color-greenBg;
        color: $color-green;
    }
    &.status6 {
        background-color: $color-blue-pills-bg;
        color: $color-blue-dark;
    }
    &.status7 {
        background-color: $color-warning;
        color: $color-warningText;
    }
}
    &__img {
        position: relative;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        aspect-ratio: 4/2.8;

        img {
            max-width: 100%;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition-duration: 300ms;
            transition-property: transform;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    &__imgArrows {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        opacity: 0;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 0;
            background-color: transparent;
            transition: all 0.2s;
            color: var(--color-white);

            svg {
                width: 20px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &__imgDots {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 10px;
        width: fit-content;
        gap: 5px;
        height: 17px;
        background: rgba(33, 30, 30, 0.329);
        border-radius: 55px;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding: 0 10px;
        gap: 5px;
    }

    &__imgDot {
        @include round-image(10px);
        background-color: $color-white;
        opacity: 0.4;

        &.active {
            opacity: 1;
        }
    }
}