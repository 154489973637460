@import '../../assets/scss/variables.scss';
.banner{
background: $gradient10;
padding: 96px 0;
font-family: $font-poppins;
&__info{
    max-width: 900px;
    margin-bottom: 48px;
    h1{
        font-size: 72px;
        font-weight: 800;
    }
    p{
        margin-top: 24px;
        font-size: 24px;
    }
}

}
.section{
    padding: 96px 0;
    background-color: var(--color-white);
    &.bgGray{
        background-color: $color-gray-2;
    }
    &.bgGradient{
        background: $gradient10;
    }
    &:nth-child(odd){
        .section__row{
            flex-direction: row-reverse;
        }
    }
    &__row{
        gap: 48px;
    }
    &__col{
        width: calc(50% - 24px);
    }
    img{
        max-width: 100%;
        border-radius: 8px;
    }
    h2{
        text-align: center;
        margin-bottom: 64px;
        font-size: 48px;
        font-weight: 800;
    }
    p{
        font-size: 18px;
        &:not(:last-child){
            margin-bottom: 20px;
        }
    }
    ul{
        li{
            font-size: 18px;
            gap: 10px;
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
    }
}