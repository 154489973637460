@import '../../assets/scss/variables.scss';

.skip {
    background: $brand-blue-gradient;
    padding: 96px 0;
    color: var(--color-white);
    @include max-screen($tablet) {
        padding: 30px 0;
       }
    header {
        text-align: center;
        max-width: 800px;
        margin: 0 auto 48px;
        @include max-screen($tablet) {
            margin: 0 auto 30px;
           }

        h2 {
            font-size: 36px;
            color: var(--color-white);
            @include max-screen($tablet) {
                font-size: 26px;
               }
        }

        p {
            margin-top: 16px;
            font-size: 18px;
            color: var(--color-gray-50);
            @include max-screen($tablet) {
                font-size: 16px;
               }
            
        }
    }

    &__row {
        max-width: 1152px;
        margin: 0 auto;
        gap: 32px;
    }
    &__box{
        width: calc(33.3% - 22px);
        background-color: rgba($color-white, 0.10);
        padding: 32px;
        backdrop-filter: 4px;
        border-radius: 12px;
        border: 1px solid  rgba($color-white, 0.05);
        transition-duration: .3s;
        transition-property: all;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        display: flex;
        flex-direction: column;
        @include max-screen($tablet) {
           width: 100%;
           }
        figure{
            width: 48px;
            height: 48px;
            background-color: rgba($color-white, 0.1);
            margin-bottom: 16px;
            border-radius: 8px;
        }
        h3{
            font-size: 20px;
            margin-bottom: 12px;
            font-weight: 600;
            color: var(--color-white);
        }
        p{
            font-size: 16px;
            color: var(--color-gray-50);
        }
    }
&__boxInfo{
   min-height: 173px;
}
    &__text {
        text-align: center;
        margin-top: 64px;

        p {
            font-size: 24px;
            font-family: $font-poppins;
            font-weight: 500;
            font-style: italic;
        }
    }
    &__boxFooter{
        margin-top: 20px;
        background-color: rgba($color-white, 0.1);
        padding: 12px;
        border: 1px solid  rgba($color-white, 0.05);
        gap: 12px;
        border-radius: 8px;
        font-family: $font-poppins;
        figure, img{
            @include round-image(32px);
        }
        h5{
            font-size: 14px;
            font-weight: 500;
            color: var(--color-white);
        }
        p{
            font-size: 12px;
            color:  rgba($color-white, 0.6);
            &.colorLight{
                color: var(--color-gray-50);
            }
        }
        svg{
            width: 16px;
            color:  rgba($color-white, 0.6);
        }
    }
}