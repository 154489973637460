@import '../../assets/scss/variables.scss';

.dropdown {
    display: none;
    position: absolute;
    top: 25px;
    width: 200px;
    background-color: var(--color-white);
    padding: 10px;
    z-index: 2;
    box-shadow: $shadow2;


    @include max-screen($tablet) {
        position: static;
        text-align: left;
        width: 100%;
        display: none !important;
        box-shadow: 0 0 0;
    }

    li {
        padding: 0;

        a {
            padding: 5px;
            font-size: 14px;
            display: block;
        }
    }

    &.active {
        display: block !important;
    }
}

.dropdownIcon {
    gap: 4px;
    color: var(--color-primary);
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
    font-family: $font-poppins;

    @include max-screen($tablet) {
        justify-content: space-between;
        width: 100%;
    }
    &:hover {
        background: $brand-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &.active {
        svg{
            transform: rotate(180deg);
        }
        
    }
}