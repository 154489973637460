@import '../../../assets/scss/variables.scss';

.customer {
    padding: 96px 0;
    background-color: var(--color-white);
    @include max-screen($tablet) {
        padding: 30px 0;
    }
    &.extraBottomSpace{
        padding-bottom: 200px;
        @include max-screen($tablet) {
            padding-bottom: 150px;
        }
    }
    header {
        margin-bottom: 48px;
        @include max-screen($mobile) {
           text-align: center;
        }
        h2 {
            font-size: 36px;
            font-weight: 600;
            margin: 0;
            letter-spacing: -0.2px;

            @include max-screen($tablet) {
                font-size: 22px;
            }
        }

        p {
            font-size: 20px;
            margin-top: 15px;
            @include max-screen($tablet) {
                margin-top: 8px;
                font-size: 16px;
            }
            @include max-screen($mobile) {
                font-size: 14px;
            }
        }
    }
    &__slider {
        transform: translateX(-684px);
        width: calc(100% + 684px);
        margin: 0 auto;
        padding-left: 50%;
        @include max-screen($desktop-between) {
            transform: none;
            width: 100%;
            padding-left: 16px;
        }
        @include max-screen($tablet) {
            padding: 0 16px;
        }

        :global {
            .slick-slide {
                padding-right: 24px;
                @include max-screen($tablet) {
                    padding: 0 16px;
                }
            }

            .slick-slider {
                overflow: visible;
            }

            .slick-arrow {
                color: var(--color-gray-600) !important;
                @include round-image(56px);
                display: flex !important;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--color-border);
                z-index: 99;
                bottom: -100px;
                top: auto;
                left: 0;

                @include max-screen($mobile) {
                    left: -80px;
                    right: 0;
                    margin: 0 auto;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }

                &.slick-next {
                    right:auto;
                    left: 90px;
                    @include max-screen($mobile) {
                        left: 0;
                        right: -80px;
                        margin: 0 auto;
                    }
                  
                }

                &.slick-disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    .customerCard {
        background: rgba($color-white, 0.08);
        cursor: pointer;
        border-radius: 22px;
        box-shadow: $shadow12;

        margin: 10px 4px;
        position: relative;
        transition: all 0.2s ;
        @include max-screen($mobile) {
            max-width:400px;
            margin: 0 auto;
        }

        &:hover {
            background: $brand-gradient padding-box, $brand-gradient border-box;
            border: 5px solid transparent;
            box-shadow: 0px 8px 4px 0px rgba($color-black, 0.251);

            .playPauseBtn {
                opacity: 1;

            }

            .cardBottom {
                transform: translateY(120%);
            }
        }
    
    
    
        .playPauseBtn {
            width: 0;
            height: 0;
            @include round-image(66px);
            display: flex;
            align-items: center;
            transition: all 0.2s;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
            justify-content: center;
            background: #FAFAFA4D;
            box-shadow: 0px 0px 0px 0px #EA1B1B inset;

            opacity: 0;



            svg {
                width: 30px;
                height: 30px;
                path {
                    stroke: $color-white !important;
                    fill:$color-white;
                }

            }

        }

        .cardImg {
            width: 100%;
            overflow: hidden;
            position: relative;
            aspect-ratio: 2/3.1;
         


            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 22px;
                &::-webkit-media-controls-panel {
                    background-image: linear-gradient(transparent, transparent) !important;
                }

                &::-webkit-media-controls-overlay-play-button {
                    opacity: 0;
                }
            }

            &::after {
                position: absolute;
                width: 100%;
                content: "";
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                pointer-events: none;
                transition: 0.2s all;
                background: linear-gradient(180deg, rgba(5, 1, 14, 0) 54.96%, rgba(5, 1, 14, 0.56) 79.94%, rgba(5, 1, 14, 0.8) 100%);
                border-radius: 22px;
            }

            .cardBottom {
                position: absolute;
                bottom: 24px;
                left: 24px;
                z-index: 2;
                width:calc(100% - 48px);
                padding: 24px;
                gap: 12px;
                pointer-events: none;
                transition: 0.2s all;
                color: var(--color-white);
                background-color: rgba($color-white, 0.15);
                backdrop-filter: blur(10px);
                border-radius: 8px;


                &__star {
                    gap: 4px;
                    margin-bottom: 15px;

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $color-yellow-dark;
                        color: $color-yellow-dark;
                    }
                }

                h5 {
                    font-size: 30px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    color: var(--color-white);
                    @include max-screen($tablet) {
                        font-size: 20px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    @include max-screen($tablet) {
                        font-size: 14px;
                    }
                    
                }
            }
         
        }

        img {
            width: 100%;
        }

        &.videoPlay {
            .cardImg {
                &::after {
                    display: none;
                }
            }
        }
    }
}