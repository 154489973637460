@import '../../assets/scss/variables.scss';

.choices {
    background-color: var(--color-white);
    padding: 96px 0;

    @include max-screen($tablet) {
        padding: 30px 0;
    }

    &.bgGradient {
        background: $brand-bg-gradient;
    }

    &__wrapper {
        max-width: 1152px;
        margin: 0 auto;
    }

    header {
        text-align: center;

        margin: 0 auto 64px;

        @include max-screen($tablet) {
            margin: 0 auto 30px;
        }

        h2 {
            font-size: 36px;

            @include max-screen($tablet) {
                font-size: 26px;
            }
        }

        p {
            margin: 16px auto 0;
            font-size: 20px;
            max-width: 980px;

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
    }

    &__table {
        max-width: 1200px;
        margin: 0 auto;
        border: 1px solid var(--color-gray-200);
        border-radius: 12px;
        box-shadow: 0px 1px 2px 0px rgba($color-black, 0.051);
        overflow: hidden;

        table {
            width: 100%;
            border-collapse: collapse;

            tr {
                th, td{
                    padding: 24px;
                    text-align: center;
                    background-color: var(--color-white);

                    &:first-child {
                        text-align: left;
                    }
                    &:nth-child(2) {
                        background-color: var(--color-gray-50);
                     }
                }
                th {      
                    font-family: $font-poppins;
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--color-primary);            
                }

                td {
                    font-size: 16px;
                    color: var(--color-gray-600);
                    border-top: 1px solid var(--color-gray-200);
                }
            }
        }
    }


    &__text {
        text-align: center;
        margin-top: 48px;

        @include max-screen($tablet) {
            margin-top: 24px;
        }

        p {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 32px;
            font-style: italic;
            color: var(--color-primary);
            font-family: $font-poppins;

            @include max-screen($tablet) {
                font-size: 18px;
            }
        }
    }

    &__tableTick {
        gap: 8px;
        font-weight: 500;
        color: var(--color-primary);
        font-family: $font-poppins;

        span {
            @include round-image(32px);
            background-color: var(--color-primary);
            color: var(--color-white);

            svg {
                width: 20px;
            }
        }
    }

    &__tableTickNo {
        font-family: $font-family-base;

        span {
            background-color: var(--color-error);
        }
    }

    &__testimonial {
        margin-top: 32px;
        background-color: var(--color-active);
        border-radius: 12px;
        padding: 32px;
        gap: 20px;
        box-shadow: $shadow12;

        @include max-screen($tablet) {
            flex-direction: column
        }

        figure,
        img {
            @include round-image(80px);
        }

        p {
            font-size: 16px;
            font-style: italic;
            font-family: $font-poppins;
            color: var(--color-gray-600);
        }

        h5 {
            font-size: 16px;
            font-weight: 500;
            margin-top: 16px;

            &+p {
                font-size: 14px;
                font-style: normal;
            }
        }
    }
}