@import '../../../assets/scss/variables.scss';
.howItWork {
    padding-top: 96px;
    @include max-screen($tablet) {
        padding-top: 30px;
    }
    header {
        text-align: center;
        padding-bottom: 80px;
        @include max-screen($tablet) {
            padding-bottom: 30px;
        }

        h5 {
            font-size: 20px;
            font-weight: 600;
            color: var(--color-gray-600);
            @include max-screen($mobile) {
                font-size: 16px;
            }
        }

        h2 {
            font-size: 59px;
            font-weight: 600;
            letter-spacing: -0.2px;

            @include max-screen($desktop) {
                font-size: 46px;
            }

            @include max-screen($tablet) {
                font-size: 36px;
            }
            @include max-screen($mobile) {
                font-size: 26px;
            }
        }

        p {
            max-width: 768px;
            margin: 0 auto;
            font-size: 20px;
            @include max-screen($tablet) {
                font-size: 16px;
                margin-top: 0;
            }
            @include max-screen($mobile) {
                font-size: 14px;
            }
        }
    }
    &__video{
        overflow: hidden;
        video{
            width: 100%;
            transform: scale(1.09);
         }
    }
}