@import '../../assets/scss/variables.scss';

.main {
    background: $brand-bg-gradient;
    padding: 64px 0;
    @include max-screen($tablet) {
        padding: 30px 0;
       }
    header {
        text-align: center;
        margin-bottom: 48px;
        @include max-screen($tablet) {
            margin-bottom: 30px;
           }
        h2 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 26px;
               }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            @include max-screen($tablet) {
                font-size: 16px;
               }
        }
    }



    &__feature {
        max-width: 1200px;
        margin: 64px auto 0;
        gap: 24px;
        @include max-screen($tablet) {
            margin: 30px auto 0;
           }
    }

    &__featureCard {
        background-color: var(--color-white);
        border-radius: 16px;
        padding: 24px;
        width: calc(33.3% - 16px);
        transition-duration: .5s;
        position: relative;
        overflow: hidden;
        @include max-screen($tablet) {
            width: calc(50% - 12px);
           }
           @include max-screen($mobile) {
            width: 100%;
           }



        figure {
            width: 48px;
            height: 48px;
            min-width: 48px;
            border-radius: 12px;
            background-color: rgba($color-primary, 0.05);
            margin-bottom: 16px;
            svg {
                color: var(--color-primary);
              
            }
        }

        h3 {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }
    }
    &__extracts{
        margin: 64px auto 0;
        max-width: 896px;
        background-color: var(--color-white);
        border-radius: 16px;
        padding: 40px;
        box-shadow: $shadow12;
        @include max-screen($tablet) {
            padding: 20px;
           }
        h2{
            font-size: 20px;
            font-weight: 600;
            gap: 10px;
            @include max-screen($mobile) {
                font-size: 18px;
               }
            svg{
                color: var(--color-error);
            }
        }
        ul{
            margin-top: 24px;
            li{
                width: 33.3%;
                margin-bottom: 24px;
                display: flex;
                align-items: center;
                gap: 16px;
                @include max-screen($mobile) {
                    width: 100%;
                   }
                span{
                    @include round-image(20px);
                    background-color:var(--color-greenBg);
                    color: var(--color-green);
                    svg{width: 16px;}
                }
            }
        }
        p{
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: var(--color-primary);
            margin-top: 5px;
            gap: 10px;
            @include max-screen($mobile) {
                font-size: 16px;
                text-align: left;
               }
            svg{
                color: $color-yellow-dark;
                min-width: 16px;
            }
        }
    }
}