@import '../../assets/scss/variables.scss';

.main {

       &__service{
        background: var(--color-white);
        padding: 64px 0;
        @include max-screen($tablet) {
            padding: 30px 0;
           }
       }
       &__choose{
        background: $brand-bg-gradient;
        padding: 64px 0;
        @include max-screen($tablet) {
            padding: 30px 0;
           }
       }
    header {
        text-align: center;
        margin-bottom: 48px;
        @include max-screen($tablet) {
            margin-bottom: 30px;
           }
        h2 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 26px;
               }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 810px;
            @include max-screen($tablet) {
                font-size: 16px;
               }
        }
    }



    &__feature {
        max-width: 1200px;
        margin: 47px auto 0;
        @include max-screen($tablet) {
            margin: 30px auto 0;
           }
    }

    &__featureCard {
        background-color: var(--color-white);
        border-radius: 16px;
        padding: 24px;
        width: 100%;
        border: 2px solid var(--color-gray-100);
        transition-duration: .5s;
        position: relative;
        overflow: hidden;
        min-height: 220px;
           &:not(:last-child){
            margin-bottom: 23px;
           }

        figure {
            width: 64px;
            height: 64px;
            min-width: 64px;
            border-radius: 12px;
            background-color: $color-blueBorder;
            margin-bottom: 6px;
            color: rgba($color-primary, 0.65);
            svg {
                
                width: 26px;
              height: 26px;
            }
          
        }

        h3 {
            font-size: 18px;
            margin-bottom: 4px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }
        span{
            position: absolute;
            right: 24px;
            top: 24px;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 3px 8px;
            border-radius: 55px;
            font-size: 12px;
            font-weight: 700;
            font-family: $font-poppins;
        }
        &:nth-child(2){
            figure{
                background-color: $color-warningBg;
                color: $color-warningText;
            }
        }
        &:nth-child(3){
            figure{
                background-color: $color-greenLight;
                color: $color-green-light;
            }
        }
    }
    &__chooseRow{
        max-width: 1200px;
        margin: 47px auto 0;
        gap: 32px;
        @include max-screen($tablet) {
            margin: 30px auto 0;
           }
    }
    &__chooseCard{
        background-color: var(--color-white);
        border-radius: 16px;
        padding: 32px;
        width: calc(33.3% - 22px);
        transition-duration: .5s;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 1px 2px 0px rgba($color-black, 0.051);
        @include max-screen($tablet) {
           width: 100%;
           padding: 24px;
           }

        figure {
            width: 64px;
            height: 64px;
            min-width: 64px;
            border-radius: 12px;
            background-color: $color-blueBorder;
            margin-bottom: 24px;
            color: rgba($color-primary, 0.65);
            svg {
                
                width: 26px;
              height: 26px;
            }
          
        }

        h3 {
            font-size: 20px;
            margin-bottom: 16px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }
        span{
            position: absolute;
            right: 24px;
            top: 24px;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 3px 8px;
            border-radius: 55px;
            font-size: 12px;
            font-weight: 700;
            font-family: $font-poppins;
        }
        &:nth-child(2){
            figure{
                background-color: $color-greenLight;
                color: $color-green-light;
            }
        }
        &:nth-child(3){
            figure{
                background-color: rgba($color-magenta-bg, 0.3);
                color: rgba($color-magenta-text, 0.9);
            }
        }
    }
  &__steps{
    width: 400px;
    @include max-screen($tablet) {
        width: 100%;
       }
  }
  &__video{
    width: calc(100% - 400px);
    overflow: hidden;
    @include max-screen($tablet) {
        width: 100%;
        margin-top: 30px;
       }
    video {
        width: 100%;
        height: 100%;
        transform: scale(1.09);
    }
  }
}