@import '../../assets/scss/variables.scss';



.aiCallingTable {
        max-width: 1200px;
        margin: 0 auto;
        border: 1px solid var(--color-gray-200);
        border-radius: 12px;
        box-shadow: 0px 1px 2px 0px rgba($color-black, 0.051);
        overflow-x: auto;

        table {
            width: 100%;
            border-collapse: collapse;

            tr {
                th, td{
                    padding: 24px;
                    text-align: center;
                    background-color: var(--color-white);
                    width: 240px;
                    @include max-screen($tablet) {
                        padding: 16px;
                        min-width: 150px;
                    }
                    &:first-child {
                        text-align: left;
                    }
                    &:nth-child(2) {
                        background-color: var(--color-gray-50);
                     }
                }
                th {      
                    font-family: $font-poppins;
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--color-primary);            
                }

                td {
                    font-size: 16px;
                    color: var(--color-gray-600);
                    border-top: 1px solid var(--color-gray-200);
                    &.alignLeft{
                        text-align: left;
                    }
                }
            }
        }
        &__tableTick {
            gap: 8px;
            font-weight: 500;
            color: var(--color-primary);
            font-family: $font-poppins;
    
            span {
                @include round-image(32px);
                background-color: var(--color-primary);
                color: var(--color-white);
    
                svg {
                    width: 20px;
                }
            }
        }
    
        &__tableTickNo {
            font-family: $font-family-base;
    
            span {
                background-color: var(--color-error);
            }
        }
        &__tableText{
            font-weight: 500;
        }
    }






