@import '../../assets/scss/variables.scss';

.uploadList {
    .headerIcon {
        position: relative;
        z-index: 2;
        margin-bottom: 25px;

        &:before,
        &:after {
            content: "";
            width: 208px;
            height: 208px;
            display: block;
            position: absolute;
            top: -81px;
            left: -86px;
            z-index: 1;
            pointer-events: none;
            background-color: var(--color-white);

        }

        &:after {
            @include background-image(animated-circle, gif, center center, no-repeat, 200px);
            width: 216px;
            height: 216px;
            mix-blend-mode: difference;
            opacity: 5%;

        }

        svg {
            position: relative;
            z-index: 2;
            width: 48px;
            height: 48px;
            padding: 10px;
        }

    }

    &__header {
        margin-bottom: 20px;
        position: relative;
        z-index: 3;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }
    &__action {
        margin-top: 32px;
    }
    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--color-button);
    }

    .step2 {
        :global {
            .inputSelect {
                margin-bottom: 10px;
            }
        }
    }

    .mainCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &__half {
            width: 50%;
            padding: 0 10px;
            position: relative;

            @include max-screen($mobile) {
                width: 100%;
            }
            &.trashBtn{
                padding-right: 30px;
            }
        }

        &__oneThird {
            width: 33.3%;
            padding: 0 10px;

            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }
    .loader{
        border-radius: 12px;
        border: 1px dashed var(--color-border);
        background: var(--color-white);
        padding: 16px 24px;
        height: 126px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 16px;
    }
  .uploadFile{
    border-radius: 8px;
    padding: 9px 14px;
    border: 1px dashed var(--color-border);
    box-shadow:  $shadow;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--color-textLight);
    display: inline-flex;
    gap: 15px;
    width: 100%;
    &__name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block !important;
    }
    span{
        display: inline-flex;
        color: var(--color-gray);
    }
  }
  .listDesc{
    margin: 10px 0 20px;
    h3{
        font-size: 16px;
        color: var(--color-button);
    }
    p{
        font-size: 14px;
        color: var(--color-textLight);
    }
  }
  .deleteRow{
    position: absolute;
    right: 0;
    top: 35px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0;
    color: var(--color-error);
    cursor: pointer;
  }
}