@import '../../../assets/scss/variables.scss';

.banner {
    overflow: hidden;
    &__text {
        width: 50%;
        padding: 200px 15px 30px 30px;
        @include max-screen($tablet) {
            width: 100%;
            justify-content: center;
            padding: 30px;
        }
    }

    &__info {
        transition: all 0.2s;
        position: fixed;
        @include max-screen($tablet) {
            text-align: center;
            transform:none !important;
            position: static;
        }

        h1 {
            font-size: 56px;

            @include max-screen($desktop) {
                font-size: 36px;
            }

            @include max-screen($tablet) {
                font-size: 26px;
            }

            span {
                background: $brand-gradient;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: block;
            }
        }

        p {
            margin-top: 20px;
            font-size: 20px;
            max-width: 570px;

            @include max-screen($tablet) {
                margin-top: 10px;
            }
        }
    }

    &__cards {
        width: 50%;
        overflow: hidden;

        @include max-screen($tablet) {
            width: 100%;
            padding: 30px;
        }
    }

    &__button {
        margin-top: 40px;
        gap: 20px;

        @include max-screen($tablet) {
            justify-content: center;
            margin-top: 20px;
        }
    }

    &__cardsRow {
        gap: 20px;
        padding: 15px 0;
    }
}