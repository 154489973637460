.dateRangePickerMain {
    position: relative;

    .dateRangePicker {
        border-radius: 12px;
        border: 1px solid var(--color-border);
        background: var(--color-white);
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        width: fit-content;
        position: relative;
        padding-bottom: 64px;

        @include max-screen($tablet) {
            max-height: calc(100% - 100px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-bottom: 0px;
        }

        .rdrDateRangePickerWrapper {
            border-radius: 12px;
            overflow: hidden;
            @include max-screen($tablet) {
               flex-direction: column;
            }
        }

        .rdrDefinedRangesWrapper {
            width: 192px;
            border-right: 1px solid var(--color-border);
            @include max-screen($tablet) {
              width: 100%;
              border: 0;
             }
            
        }
        .rdrMonthName {
            font-weight: 600;
            font-size: 16px;
            color:var(--color-button);
            text-align: center;
            @include max-screen($tablet) {
                padding: 0;
               }
        }
        .rdrMonths {
            border-bottom: 1px solid var(--color-border);

            @include max-screen($tablet) {
                flex-direction: column;
            }

            .rdrMonth {
                padding:0 20px 20px;
                width: 328px;

                &:first-child {
                    border-right: 1px solid var(--color-border);

                    @include max-screen($tablet) {
                        border-right: 0;
                    }
                }
            }
        }

        .rdrStaticRanges {
            padding: 12px 16px;
            @include max-screen($tablet) {
                flex-direction: row;
                white-space: nowrap;
                overflow-x: auto;
                max-width: 320px;
               }
            .rdrStaticRange {
                border: 0;

                &:hover,
                &:focus {
                    .rdrStaticRangeLabel {
                        background-color: var(--color-active);
                    }
                }
            }

            .rdrStaticRangeLabel {
                padding: 11px 16px;
                font-size: 14px;
                font-weight: 500;
                color: var(--color-button);
                font-family: $font-family-base;
                border-radius: 6px;

                i {
                    font-style: normal;
                }
            }
        }

        .rdrDay {
            font-size: 14px;
            height: 41px;
            font-weight: 400;
            font-family: $font-family-base;
            color: var(--color-button);


        }

        .rdrSelected,
        .rdrInRange,
        .rdrStartEdge,
        .rdrEndEdge {
            height: 41px;
            width: 41px;
            top: 0;
            right: 0;
            left: 0;
            border-bottom: 1px solid var(--color-white);

        }
        .rdrStartEdge{
            border-top-left-radius: 55px;
            border-bottom-left-radius: 55px;
        }
        .rdrEndEdge{
            border-top-right-radius: 55px;
            border-bottom-right-radius: 55px;
        }
        .rdrWeekDay {
            font-size: 14px;
            font-weight: 500;
            font-family: $font-family-base;
            color: var(--color-button);
        }

        &__footer {
            padding: 16px;
            margin-left: 191px;
            border-left: 1px solid var(--color-border);
            gap: 16px;

            @include max-screen($tablet) {
                margin-left: 0;
                flex-direction: column;
            }
        }

        &__button {
            position: absolute;
            right: 16px;
            bottom: 16px;
            gap: 16px;
            @include max-screen($tablet) {
                padding: 16px;
               position:static;
               display: flex;
               align-items: center;
               justify-content: center;
               }
        }
        .rdrDateDisplayWrapper {
            position: absolute;
            width: 100%;
            bottom: 0;
            background-color: transparent;
            max-width: 280px;
            left: 191px;
            border-left: 1px solid var(--color-border);
            padding-left: 10px;
            @include max-screen($tablet) {
               position: static;
               max-width: 100%;
               border: 0;
                }
            .rdrDateInput {
                border: 1px solid var(--color-borderDark);
                border-radius: 8px;
                box-shadow: 0 1px 2px 0 rgba(16,24,40,.05);
                font-size: 16px;
                font-weight: 400;
                height: 44px;
                outline: 0;
                padding: 10px 14px;
                width: 100%;
                display: flex;
                align-items: center;
               
                input{
                    text-align: left;
                    color: var(--color-button);
                    font-family: $font-family-base;
                    @include max-screen($tablet) {
                       width: 110px;
                       }
                }
            }
        }
        
    
    }

    .dateRangePickerInput {
        border-radius: 8px;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        outline: 0;
        height: 41px;
        cursor: pointer;
        position: relative;
        &.crossDisabled{
            padding: 0px 24px 0px 10px;
        }
        button{
            border: 0;
            width: 100%;
            height: 41px;
            font-size: 16px;
            font-weight: 400;
            background-color: transparent;
            cursor: pointer;
        }
        span{
            position: absolute;
            right: 8px;
            top: 8px;
            z-index: 2;
            svg{
                width: 18px;
            }
        }
    }

    .dateRangePickerMainModal {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        background-color: rgb(0 0 0 / 47%);
    }

    .rdrNextPrevButton {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            @include background-image(next-arrow, svg, center center, no-repeat, 8px);
            font-size: 0;
            border: 0;
            width: 20px;
            height: 20px;
            margin: 0;
        }

        &:hover {
            background: transparent;
        }
    }

    .rdrPprevButton {
        i {
            @include background-image(prev-arrow, svg, center center, no-repeat, 8px);
        }

    }

    .filterRow {

        .filterRow__col {
            width: calc(50% - 10px);

            input[type="text"] {
                max-width: 136px;
                font-family: $font-family-base;
            }

        }
    }

    &.dateRangeDisabled {
        .dateRangePickerInput{
            background-color: var(--color-grayBg);
            cursor: not-allowed !important;
            border-radius: 8px;
            color: var(--color-borderDark);
        }
       
    }
      .no-calendar{
        cursor:pointer !important;
        &::-webkit-calendar-picker-indicator {
            display: none;
          }
      }
}
.rdrSelected{
    background: linear-gradient($color-border, $color-border) padding-box, $gradient8 border-box !important;
    border: 2px solid transparent;
}
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: $color-black !important;
}