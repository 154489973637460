@import '../../assets/scss/variables.scss';
.blog{
    padding: 50px 0;
    &__row{
        gap: 15px;
    }
    &__col{
        width: calc(33.3% - 10px);
        @include max-screen($tablet) {
            width: calc(50% - 10px);
         }
         @include max-screen($mobile) {
            width: 100%;
         }
    }
}