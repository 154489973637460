h1,
h2,
h3,
h4,
h5,
h6 {
 font-family: $font-poppins;
 color: var(--color-primary);
 font-weight: 700;
 line-height: 1.2;

}