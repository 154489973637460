@import '../../assets/scss/variables.scss';

.billing {
   
    &__header {
        padding-bottom: 24px;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 24px;
        gap: 8px 24px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
        }

        p {
            font-size: 14px;
            margin-bottom: 15px;
            color: var(--color-textLight);
        }

        &.billingHistory {
            border-bottom: 0;
            width: 100%;
            margin-bottom: 0;
            gap:15px;
        }
    }
&__tableMain{
    position: relative;
}
    &__cards {
        margin: 24px 0 32px;
        gap: 15px 32px;
       
        .cardsLeft {
            width: 280px;
            @include max-screen($stablet) {
                width: 100%;
             }
            h4 {
                font-size: 14px;
                font-weight: 600;
                color: var(--color-button);
            }

            p {
                font-size: 14px;
                color: var(--color-textLight);
            }
        }

        .cardsRight {
            width: calc(100% - 312px);
            @include max-screen($stablet) {
                width: 100%;
             }
            .cards {
                padding: 16px;
                border-radius: 12px;
                border: 1px solid var(--color-border);
                background-color: var(--color-white);
                gap: 12px;
                margin-bottom: 12px;
                max-width: 640px;
                position: relative;
                overflow: hidden;
                figure {
                    border-radius: 6px;
                    border: 1px solid var(--color-border);
                    background-color: var(--color-white);
                    display: flex;
                    width: 46px;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                }

                h6 {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--color-button);
                }

                p {
                    font-size: 14px;
                    color: var(--color-textLight);
                }

                &__link {
                    gap: 12px;
                    margin-top: 8px;

                    span {
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--color-textLight);
                        gap: 6px;

                        &:hover {
                            color: var(--color-primary);
                        }

                        &:last-child {
                            color: var(--color-primary);

                            &:hover {
                                color: var(--color-textLight);
                            }
                        }
                    }
                }
            }

            .addCards {
                span {
                    font-weight: 600;
                    font-size: 14px;
                    color: var(--color-textLight);
                    gap: 6px;
                    padding-top: 15px;

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
    &__cardsWrap {
        max-height: 350px;
        overflow-y: auto;
    }

}
.registerInfo {
    width: 100%;
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 12px;
    margin-bottom: 12px;

    svg {
        color: #000 !important;
    }
    aside {
        font-size: 14px;
        line-height: 1.4;
        padding-left: 7px;
        width: calc(100% - 38px);
        strong {
            font-weight: 600;
            color: var(--color-button);
        }
        p {
            color: var(--color-textLight);
        }
    }
   
 
}
.cardRow {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    margin-bottom: 16px;

    &__col {
        width: calc(100% - 180px);

        @include max-screen($mobile) {
            width: 100%;
        }
    }

    &__col3 {
        width: 163px;

        @include max-screen($mobile) {
            width: 100%;
        }
    }

    &__full {
        width: 100%;
        padding: 8px 0;
    }
}
.cardInput {
    label {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-button);
        display: block;
        margin-bottom: 6px;
    }
    :global {
        .StripeElement {
            border-radius: 8px;
            padding: 13px 14px;
            border: 1px solid var(--color-borderDark);
            background: var(--color-white);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            outline: 0;
            height: 44px;
        }
    }
}
.isError {
    :global {
        .StripeElement {
            border-color: var(--color-warningText);
        }
        span {
            line-height: 1.2;
            display: block;
            margin-top: 2px;
        }
    }
}
.loaderCards{
    background-color: rgb(181 181 181 / 39%);
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
}
.trial {
    margin-bottom: 10px;
}

.trial,
.total,
.totalRow {
    width: 100%;

    h5 {
        font-size: 16px;
        font-weight: 600;
        color: var(--color-text);
    }

    p {
        font-size: 14px;
        color: var(--color-gray);
    }

    .right {
        text-align: right;
    }
}

.total {
    p {
        font-size: 16px;
        color: var(--color-text);
    }
}
