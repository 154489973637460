@import '../../assets/scss/variables.scss';

.discover {
    padding-top: 96px;
    @include max-screen($tablet) {
        padding-top: 60px;
     }
    header {
        padding-bottom: 80px;
        @include max-screen($tablet) {
            padding-bottom: 20px;
         }
        @include max-screen($mobile) {
            text-align: center;
         }
        h5 {
            font-size: 20px;
            font-weight: 500;
            color: var(--color-gray-600);
            @include max-screen($mobile) {
                font-size: 14px;
            }
        }

        h3 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 22px;
            }
        }

        p {
            font-size:18px;
            max-width: 768px;
            margin-top: 16px;
            @include max-screen($tablet) {
                font-size: 16px;
                margin-top: 0;
            }
            @include max-screen($mobile) {
                font-size: 14px;
            }
        }
    }

&__blank{
    width: 12px;
    height: 2px;
    background-color: var(--color-borderDark);
    margin: 0 auto;
}
    &__table {
        margin-top: 30px;
        border-radius: 12px;
        background: var(--color-white);
        @include max-screen($tablet) {
           overflow-x: auto;
        }
        table {
            border-collapse: collapse;
            width: 100%;
            thead{
                position: sticky;
                top: 80px;
                background: var(--color-white);
                @include max-screen($tablet) {
                    position: static;
                    top: 0;
                 }
            }
            tr {
                th {
                    padding: 16px 30px;
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--color-primary);
                    text-align: center;
                    white-space: nowrap;
                    font-family: $font-poppins;
                    cursor: grab;

                    @include max-screen($tablet) {
                        min-width: 100px !important;
                        font-size: 14px;
                    }
                    &:first-child{
                        text-align: left;
                    }
            
                }

                td {
                    padding: 16px 15px;
                    font-size: 16px;
                    color: var(--color-gray-600);
                    text-align: center;
                    height: 72px;
                    &:first-child{
                        text-align: left;
                        width: 270px;
                        border-radius: 8px 0 0 8px;
                    }
                    &:last-child{
                        border-radius: 0 8px 8px 0;
                    }
                }

                &:nth-child(odd) {
                    td {
                        background-color: var(--color-gray-50);
                        
                    }

                }
            }
        }
    }

    &__wrapper {
        overflow-x: auto;
    }
    &__check{
        @include round-image(24px);
        margin: 0 auto;
        background-color: var(--color-greenLight);
        svg{
            width: 16px;
            color: var(--color-green);
        }

    }
}