@import '../../assets/scss/variables.scss';

.main {

    background: $brand-bg-gradient;
    padding: 64px 0;

    @include max-screen($tablet) {
        padding: 30px 0;
    }

    header {

        h5 {
            font-size: 14px;
            font-weight: 500;
            background-color: var(--color-gray-50);
            box-shadow: $shadow12;
            padding: 6px 16px;
            border-radius: 55px;
            display: table;
        }

        h2 {
            font-size: 36px;
            margin-top: 16px;

            @include max-screen($tablet) {
                font-size: 26px;
            }
        }
        h3{
            margin-top: 32px;
            font-size: 20px;
            font-weight: 600;
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 720px;

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
        ul{
           list-style: disc;
           padding-left: 30px;
            li{
                margin-top: 24px;
            }
        }
        button{
            @include max-screen($mobile) {
                font-size: 14px;
            }
        }
    }



    &__finance {
        max-width: 1200px;
        margin: 47px auto;
        gap: 24px;

        @include max-screen($tablet) {
            margin: 30px auto;
        }
    }

    &__left {
        width: calc(45% - 12px);
        @include max-screen($tablet) {
          width: 100%;
        }
    }

    &__img {
        width: calc(55% - 12px);
        @include max-screen($tablet) {
            width: 100%;
          }
        img {
            max-width: 100%;
        }
    }

    &__note {
        background-color: var(--color-gray-50);
        box-shadow: $shadow12;
        padding: 20px 16px;
        border-radius: 8px;
        gap: 16px;
        margin: 24px 0 56px;

        figure {
            @include round-image(40px);
            background-color: var(--color-primary);
            color: var(--color-white);

            svg {
                width: 18px;
                height: 18px;
            }
        }

        span {
            font-size: 16px;
            font-weight: 500;
            margin-top: 0;
            color: var(--color-primary);
        }
    }
}