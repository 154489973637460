.stepsDialog {
    .MuiPaper-root {
        max-width: 640px;
        border-radius: 8px;
        width: 100%;

        @include max-screen($mobile) {
            margin: 0;
            max-height: 100%;
            border-radius: 0;
            height: 100%;
            max-width: 100%;
        }

        .closeDialog {
            color: var(--color-gray);
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 16px;
            top: 16px;
            z-index: 9;
        }

        .dialogWrapper {
            padding: 24px;
            &__icon {
                position: relative;
                z-index: 2;
                margin-bottom: 65px;
                display: flex;
                &:before,
                &:after {
                    content: "";
                    width: 208px;
                    height: 208px;
                    display: block;
                    position: absolute;
                    top: -81px;
                    left: -86px;
                    z-index: 1;
                    pointer-events: none;
                    background-color: var(--color-white);
        
                }
        
                &:after {
                    @include background-image(animated-circle, gif, center center, no-repeat, 200px);
                    width: 216px;
                    height: 216px;
                    mix-blend-mode: difference;
                    opacity: 5%;
        
                }
        
                svg {
                    position: absolute;
                    z-index: 2;
                    width: 25px;
                    height: 25px;
                    top: 12px;
                    left: 12px;
                }
        
            
            }
            &__header{
                margin-bottom: 15px;
                z-index: 3;
                position: relative;
                h2{
                    font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
                }
            }
            &__info{
                margin-bottom: 16px;
                z-index: 3;
                position: relative;
                h6{
                    font-size: 18px;
                    color: var(--color-text);
                    margin-bottom: 5px;
                }
                p{
                    color: var(--color-textLight);
                    font-size: 14px;
                    margin-bottom: 16px;
                }
                ul{
                    padding-left: 16px;
                    margin-bottom: 16px;
                    li{
                        list-style: disc;
                        color: var(--color-textLight);
                        font-size: 14px;
                    }
                }
                h3{
                    font-size: 16px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
                }
            }
            

          .actions{
            margin-top: 32px;
          }
          .actionsSm{
            margin-top: 16px;
        }
        
        
        }
    }
    &.propertyDetail{
        .MuiPaper-root {
        max-width: 100%;
        border-radius: 0;
        max-height: 100%;
        .dialogWrapper{
            padding-top: 0;
        }
        }
    }
    &.list-carousel{
        .MuiPaper-root {
            max-width: 100%;
            border-radius: 0;
            max-height: 100%;
            margin: 0 80px;
            @include max-screen($tablet) {
                margin: 0 20px;
            }
            .dialogWrapper{
                padding-top: 0;
            }
            } 
    }
&.contactInfoModal{
    .MuiPaper-root {
    max-width: 429px;

    @include max-screen($mobile) {
        max-width: 100%;
    }
}
}
    &.successDialog {
        .MuiPaper-root {
            max-width: 400px;

            @include max-screen($mobile) {
                max-width: 100%;
            }
        }
        .MuiBackdrop-root{
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
    &.videoModal{
        .MuiPaper-root {
            max-width: 950px;

            @include max-screen($mobile) {
                max-width: 100%;
            }
            .closeDialog{
                right: 2px;
                top: 2px;
            }
        }  
    }

    &.loginRegister {
        .MuiPaper-root {
            max-width: 400px;

            @include max-screen($mobile) {
                max-width: 100%;
            }

            .closeDialog {
                top: 25px;
            }
        }
    }
    &.welcomeDialog {
        .MuiPaper-root {
            max-width: 400px;

            @include max-screen($mobile) {
                max-width: 100%;
            }
        }
    }
    &.campaignTermsDialog{
        .MuiPaper-root {
            max-width: 400px;

            @include max-screen($mobile) {
                max-width: 100%;
            }
        } 
    }

    &.upgradeModal {
        .MuiPaper-root {
            max-width: 1400px;
            padding: 90px 60px;
            @include max-screen($desktop-xlarge) {
                padding: 60px 30px;
            }
            @include max-screen($desktop-large) {
                padding: 60px 15px;
            }
            @include max-screen($tablet) {
                padding: 15px 0;
            }
            @include max-screen($mobile) {
                max-width: 100%;
            }
        }
        &.upgradeModalSmall{
            .MuiPaper-root {
                max-width: 576px;
                padding: 0;
                @include max-screen($mobile) {
                    max-width: 100%;
                }
              .wrapper-md{
                padding: 0;
              }
              .priceCard{
                border-radius: 0;
                box-shadow: 0 0 0;
                border: 0;
              }
              .closeDialog {
                right: 5px;
                top: 5px;
            }
            }
        }
        &.showPurchaseModal{
            .MuiPaper-root {
                max-width:480px;
                padding: 0;
                @include max-screen($mobile) {
                    max-width: 100%;
                }
                .closeDialog {
                    right: 24px;
                    top: 24px;
                }
            }
        }
        &.paymentSuccess{
            .MuiPaper-root {
                max-width:400px;
                padding: 0;
                @include max-screen($mobile) {
                    max-width: 100%;
                }
            }
        }
        
    }
    &.subscriptionDialog{
        .MuiPaper-root {
            max-width: 700px;
            @include max-screen($mobile) {
                max-width: 100%;
            }
        }
    }
    &.rounded-xl{
        .MuiPaper-root {
            border-radius: 16px;
        }
    }
    &.paymentModal{
        .priceCard{
    border: 0;
    box-shadow: 0 0 0;
        }
        .priceCardInfo{
            padding:15px  20px;
        }
    }
    &.editModal{

        .MuiPaper-root {
            max-width: 217px;

            @include max-screen($mobile) {
                max-width: 100%;
            }
        }
        @include max-screen($mobile) {
            max-width: 100%;
        }
    }
    &.editModalLarge{
        .MuiPaper-root {
            max-width: 352px !important;

            @include max-screen($mobile) {
                max-width: 100%!important;
            }
        }
       
    }
  
}
.paymentModalHeading{
    padding: 20px 0 0 20px;
    h2{
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text);
    }
}


.filterMenu {
    &__list{
        &:not(:last-child){
            margin-bottom: 16px;
        }
        p{
            font-size: 14px;
        font-weight: 500;
        color: var(--color-button);
        margin-bottom: 6px;
        }
    }
}
.cardHeader {
    width: 100%;
    gap: 15px;
    @include max-screen($mobile) {
       flex-wrap: wrap;
    }
    h2 {
        font-size: 24px;
        font-weight: 600;
        color: var(--color-text);
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
        @include max-screen($desktop-xlarge) {
            font-size: 20px;
        }
        @include max-screen($desktop-large) {
            font-size: 18px;
        }
    }

    p {
        font-size: 16px;
        color: var(--color-textLight);
    }

    &__right {
        text-align: right;

        p {
            margin-top: 10px;
            color: var(--color-gray);
            font-size: 14px;
        }
    }

    .cardPrice {
        font-size: 60px;
        font-weight: 600;
        color: var(--color-text);
        display: flex;
        line-height: 1;
        @include max-screen($desktop-xlarge) {
            font-size: 40px;
        }
        @include max-screen($desktop-large) {
            font-size: 38px;
        }
        @include max-screen($tablet) {
            font-size: 28px;
        }

        small {
            font-size: 36px;

            @include max-screen($tablet) {
                font-size: 20px;
            }
        }

        &.priceStrike {
            font-size: 30px;
            padding-top: 5px;
            padding-right: 5px;
            position: relative;

            small {
                font-size: 16px;
            }

            span {
                position: absolute;
                width: 100%;
                svg{
                    width: 100%;
                }
            }
        }
    }
}

.cardInfo {
    header {
        width: 100%;
        gap: 15px;
        flex-wrap: nowrap !important;
        @include max-screen($mobile) {
            flex-wrap: wrap !important;
        }

        h3 {
            font-size: 16px;
            font-weight: 600;
            color: var(--color-text);
        }

        p {
            font-size: 16px;
            color: var(--color-textLight);
        }
        &.selectFull{
            .inputSelect {
                width:100%;
            }
        }

        .inputSelect {
            width: 50%;

            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }

    ul {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 0 16px;

        li {
            width: calc(50% - 8px);
            @include max-screen($desktop-xlarge) {
             font-size: 14px;
            }
       
            @include max-screen($tablet) {
                width: 100%;
            }
        }
    }

    .credits h3 {
        display: inline;
      }
      
      .credits span {
        display: inline;
        font-size: 15px;
      }
}

.cardSwitch {
    margin-top: 11px;
    gap: 12px;

    h5 {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-text);

        span {
            color: var(--color-blue);
        }
    }
}
.vaCardInfo{
    h5{
        font-size: 16px;
    }
    p{
        font-size: 14px;
        color: var(--color-textLight);
    }
}
.totalRow {
    padding: 10px 0 0;
    width: 100%;

    p {
        font-size: 16px;
        color: var(--color-text);

        &.gray {
            color: var(--color-gray);
        }
    }

    &.borderTop {
        border-top: 1px solid var(--color-border);
        margin-top: 10px;
        float: left;
    }

    .right {
        text-align: right;
    }
}
.filterMenuFooter {
    padding: 16px;
    width: 100%;
    gap: 12px;
    border-top: 1px solid var(--color-border);

    &__half {
        width: calc(50% - 6px);
    }
}
.filterList{
    width: 100%;
    li{
        gap: 10px;
        min-height: 30px;
        color: var(--color-textLight);
        padding: 10px;
        &:not(:last-child){
            border-bottom: 1px solid var(--color-border);
        }
        svg{
            width: 20px;
            height: 20px;
        }
        span{
            font-family: $font-family-base;
            font-size: 16px;
        }
        &.processing{
            cursor: default;
            border-radius: 12px 12px 0 0;
            &:hover{
                background-color: var(--color-white);
            }
            
        }
    }
}
.select-bold{

        .inputSelect {
           .MuiInputBase-root {
                height: 40px;
            }
            .MuiInput-input {
                color: var(--color-text);
                font-size: 16px!important;
                font-weight: 500 !important;

                &::placeholder {
                    color: var(--color-text);
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
    .listDownload {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        font-size: 16px;
        font-family: $font-family-base;
        color: var(--color-textLight);
       
        .button {
            border: 0;
            box-shadow: 0 0 0;
            padding: 0;
            width: auto;
            height: auto;
            min-height: 0;
            background: transparent;
            font-weight: 400;
            font-size: 16px;
            gap: 10px;
            color: var(--color-textLight);
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            &:focus, &:hover{
                background: transparent;
            }
        }
        .loadingBtn{
            font-size: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            min-height: 24px;
            width: 100%;
            padding: 10px;
            min-height: 44px;
        }
    }
    .backdropDisable {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        &:after {
            content: "";
            position: fixed;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
    
.downloadListStatus{
    padding: 0 !important;
}
.border-0{
    border-bottom: 0 !important;
}
.verified-list{
    flex-wrap: wrap;
    cursor: default;
    display: flex;
    gap: 8px;
    padding: 5px 10px 5px 10px;
    width: 100%;
    p{
        display: flex;
        width: 100%;
        border: 1px solid var(--color-border);
        border-radius: 6px;
        gap: 6px;
        font-size: 12px;
        font-weight: 500;
        color: var(--color-button);
        padding: 3px 8px;
        align-items: center;
    }
}
.verified-list-main{
    flex-wrap: wrap;
    border-radius: 10px !important;
    padding: 0 !important;
    overflow: hidden;
    gap: 0 !important;
    &:hover{
        background-color: var(--color-white) !important;
    }
  
.verified-list-delete{
    color: var(--color-text);
    font-size: 16px;
font-weight: 500;
gap: 8px;
display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    &:hover{
        background-color: rgba(0, 0, 0, 0.04) !important;
    }
    &.disabled{
        opacity: 0.38;
        cursor: default;
        pointer-events: none;
    }
}
}

.editModal{
    padding: 15px;
    label{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 6px;
        display: block;
        color: var(--color-textLight);
    }
    input{
        border-radius: 8px;
        padding: 10px 14px;
        border: 1px solid var(--color-borderDark);
        background: var(--color-white);
        box-shadow: $shadow;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        outline: 0;
        height: 44px;

        &::placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &::-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &:-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }

        &:-moz-placeholder {
            color: var(--color-gray);
            opacity: 1;
        }
    }
    &__button{
        margin-top: 15px;
        gap: 10px;
    width: 100%;
    }
    &__col{
width: calc(50% - 5px);
    }
}
.mt-15{
    margin-top: 15px;
}

.time-breakdown {
    margin-top: 15px;
    h5 {
        font-size: 16px;
    }
    ul {
        margin: 0;
        li {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 15px;
            background-color: $color-grayBg;
            padding: 8px;
            border-radius: 10px;
            color: $color-textLight;
            width: 100%;
            svg {
                min-width: 20px;
                color:$color-blue-bright;
            }
            span{
                color: $color-blue-bright;
            }
            &.alignStart{
                align-items: flex-start;
                svg{
                    margin-top: 5px;
                }
            }
        }
    }
}

.mainCol {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    &__half {
        width: 50%;
        padding: 0 10px;

        @include max-screen($mobile) {
            width: 100%;
            margin-bottom: 15px;
        }
        .inputGroup{
        margin-bottom: 15px;
        }
    }

}
.mainColBtn{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;

    &__half {
        width: calc(50% - 5px);

        @include max-screen($desktop-large) {
            width: 100%;
        }
        .inputGroup{
        margin-bottom: 15px;
        }
    }
}
.small-text{
    margin: 5px 0 15px;
    float: left;
    h5{
        color: var(--color-primary);
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 12px;
    }
    p{
        font-size: 12px;
    color: var(--color-button);
    }
}
