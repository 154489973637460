@import '../../assets/scss/variables.scss';

.header {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-border);
    padding: 12.5px 60px;
    position: sticky;
    top: 0;
    z-index: 999;

    @include max-screen($desktop-large) {
        padding: 12.5px 35px;
    }

    @include max-screen($tablet) {
        padding: 12.5px 15px;
    }

    @include max-screen($mobile) {
        padding: 16px 15px;
    }

    &__left {
        gap: 16px;


        .navLeft {
            @include max-screen($tablet) {
                display: none;
            }
        }
    }

    &__right {
       &.rightMenu{
           @include min-screen($tablet) {
            width: 50%;
           }
       }
        @include max-screen($tablet) {
            display: none;
        }
    }

    .mobileToggle {
        display: none;

        @include max-screen($tablet) {
            display: block;
        }
    }

    &.headerBlack {
        width: 100%;
        border: 0;
        left: 0;
        right: 0;
        padding: 20px 40px;
        transition: all 0.2s;
        @include max-screen($tablet) {
            padding: 16px;
        }
    }

    .headerWrapper {
        width: 100%;
        margin: 0 auto;
    }

}

:global .header-black.scrolled {
    background-color: var(--color-white) !important;
    padding: 20px 40px;
    box-shadow:  $shadow12;
    @include max-screen($tablet) {
        padding: 16px;
    }
}