@import '../../assets/scss/variables.scss';

.filters {
    gap: 5px;
    margin-bottom: 20px;
    @include max-screen($tablet) {
        flex-wrap: wrap;
        gap: 10px;
     }
    &__left {
      width: calc(100% - 875px);
      @include max-screen($tablet) {
        width: 100%;
     }
    }
    &__right {
      width: 875px;
      @include max-screen($tablet) {
        width: 100%;

     }
  
      ul {
        gap: 5px;
        @include max-screen($tablet) {
            flex-wrap: wrap;
            gap: 10px;
         }
      }
    }
  }
