@import '../../assets/scss/variables.scss';

.main {
    background: var(--color-white);
    padding: 80px 0;
    overflow-x: hidden;
    @include max-screen($tablet) {
        padding: 30px 0;
      }


    header {
        text-align: center;
        margin-bottom: 48px;

        @include max-screen($tablet) {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 36px;

            @include max-screen($tablet) {
                font-size: 26px;
            }
        }

        p {
            margin: 16px auto 0;
            font-size: 20px;
            max-width: 810px;

            @include max-screen($tablet) {
                font-size: 16px;
            }
        }
    }



    &__works {
        max-width: 1200px;
        margin: 64px auto 0;
        gap: 48px;

        @include max-screen($tablet) {
            margin: 30px auto 0;
        }
    }

&__left{
    width: calc(45% - 24px);
    @include max-screen($tablet) {
        width: 100%;
    }
    ul{
        li{
            padding: 16px 16px 24px;
            border-radius: 8px;
            gap: 16px;
            transition: all 0.2s;
            &:not(:last-child){
                margin-bottom: 24px;
            }
           &.active{
            background-color: var(--color-primary);
            
            h4, p{
                color: var(--color-white);
               }
               figure{
                box-shadow: 0px 0px 10px 0px $color-blue-bright;

               }
           } 
           h4{
            font-size: 20px;
            font-weight: 600;
            
           }
           p{
            font-size: 16px;
            margin-top: 4px
           }
           figure{
            @include round-image(48px);
            color: var(--color-white);
            background: $brand-blue-gradient;
           }
        }
    }
}
&__img{
    width: calc(55% - 24px);
    padding: 15px;
    @include max-screen($tablet) {
        display: none;
    }
    @include max-screen($tablet) {
        width: 100%;
    }
    img{
        max-width: 100%;
    }
}
&__note{
    background-color: var(--color-gray-50);
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    gap: 12px;
    margin-top: 24px;
    max-width: 464px;
    figure{
        width: 48px;
        height: 48px;
        min-width: 48px;
        border-radius: 8px;
        background-color: var(--color-greenBg);
        color: var(--color-green);
        svg{
            width: 18px;
            height: 18px;
            opacity: 0.6;
        }
    }
    p{
        font-size: 16px;
        font-weight: 500;
        color: var(--color-primary);
    }
}
 &__imgMobile{
    display: none;
    padding: 15px 0;
    img{
        max-width: 100%;
    }
    @include max-screen($tablet) {
        display: block;
    }
 }

  
}
.scrollContainer {
   height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
}