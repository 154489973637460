@import '../../assets/scss/variables.scss';

.skipModal {
    &__close {
        opacity: 0.6;
    }

    &__header {
        padding: 24px 36px 24px 24px;
        gap: 16px;

        figure {
            width: 48px;
            height: 48px;
            min-width: 48px;
            border-radius: 10px;
            border: 1px solid var(--color-border);
        }

        figcaption {
            h2 {
                font-size: 18px;
                font-weight: 600;
                color: $color-blue-shade;
            }

            p {
                margin-top: 4px;
                font-size: 14px;
            }
        }
    }

    &__info {
        padding: 30px 24px;
        border-bottom: 1px solid var(--color-border);
        border-top: 1px solid var(--color-border);

        &>ul {
            gap: 20px;

            &>li {
                width: calc(50% - 10px);
                @include max-screen($mobile) {
                    width: 100%;
                }

                :global {
                    .Mui-checked + .MuiTypography-root .labelBorder{
                        border: 2px solid $color-blue-shade;
                        }
                                                
                    .MuiButtonBase-root {
                        position: absolute;
                        right: 15px;
                        top: 20px;

                    }

                    .MuiFormControlLabel-root {

                        position: relative;
                        margin: 0;
                        width: 100%;

                        .MuiSvgIcon-root {
                            width: 20px;
                            height: 20px;
                        }

                        .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
                            color: $color-blue-shade;
                            width: 20px;
                            height: 20px;
                        }
                      
                    }

                    .MuiTypography-root {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__footer {
        padding: 24px;
    }

    &__label {
        padding: 20px;
        border: 2px solid var(--color-border);
        border-radius: 10px;

        h2 {
            font-size: 20px;
            color: $color-blue-shade;

            small {
                font-size: 13px;
                font-weight: 300;
            }
        }

        h3 {
            font-size: 14px;
            font-weight: 700;
            margin-top: 8px;
            color: $color-blue-shade;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            margin-top: 2px;
        }

        ul {
            li {
                display: flex;
                align-items: center;
                gap: 0 8px;
                margin-top: 8px;
                font-weight: 400;
                font-size: 14px;

                svg {
                    width: 16px;
                    height: 16px;
                }

            }
        }
    }
}