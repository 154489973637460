@import '../../../assets/scss/variables.scss';

.rotationText {
    padding: 200px 0;
    @include max-screen($tablet) {
        padding: 100px 0;
     }
    h3 {
        font-size: 36px;
        background-color: var(--color-white);
        position: relative;
        z-index: 2;
        @include max-screen($desktop-m) {
            font-size: 26px;
        }
        @include max-screen($desktop) {
            text-align: center;
        }

        @include max-screen($mobile) {
            font-size: 18px;
        }
    }

    &__info {
        position: relative;
        // height: 240px; 
        overflow: hidden;
        // padding: 250px 0;

        &::after,
        &::before {
            content: "";
            background: linear-gradient(180deg, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);
            position: absolute;
            width: 100%;
            height: 50px;
            z-index: 1;

            @include max-screen($desktop) {
                display: none;
            }
        }

        &::after {
            bottom: 0;
        }

        &::before {
            background: linear-gradient(0deg, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);
            top: 0;
        }

        @include max-screen($desktop) {
            padding: 0;
            flex-direction: column;
            align-items: center;
            height: auto;
            width: 100%;
        }



    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding-left: 20px;
        transition: transform 0.5s ease-in-out;

        @include max-screen($desktop) {
            display: none;
        }


    }

    &__text {
        font-size: 36px;
        font-weight: 600;
        line-height: 50px;
        transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out;
        font-family: $font-poppins;
        color: var(--color-borderDark);

        &.active {
            background: $brand-gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        @include max-screen($tablet) {
            font-size: 22px;
            line-height: 1;
        }

    }

    &__mobile {
        display: none;
        font-size: 36px;
        font-weight: 600;
        background: $brand-gradient;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $font-poppins;
        text-align: center;

        @include max-screen($desktop) {
            display: block;
        }

        @include max-screen($mobile) {
            font-size: 22px;
        }
    }

    &__slider {
        display: grid;
        grid-template-columns: auto 515px;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        @include max-screen($tablet) {
           display: block;
           text-align: center;
        }
        &::after,
        &::before {
            content: "";
            background: linear-gradient(180deg, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);
            position: absolute;
            width: 100%;
            height: 100px;
            z-index: 1;
            left: 0;
        }

        &::after {
            bottom: 0;
        }

        &::before {
            background: linear-gradient(0deg, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);
            top: 0;
        }
    }

    &__sliderText {
        font-size: 36px;
        padding-left: 20px;
        font-weight: 600;
        transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out;
        font-family: $font-poppins;
        color: var(--color-borderDark);
        @include max-screen($desktop-m) {
            font-size: 26px;
        }
        @include max-screen($tablet) {
         padding-left: 0;
         }
        @include max-screen($mobile) {
            font-size: 18px;
        }
        &.active {
            background: $brand-gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    :global .slick-list {
        height: 378px !important;
        @include max-screen($desktop-m) {
            height: 304px !important;
            
        }
        @include max-screen($mobile) {
            height: 224px !important;
            
        }
    }
}