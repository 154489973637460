@import '../../assets/scss/variables.scss';

.hero {
    background: var(--color-white);
    padding: 60px 0;
    overflow-x: hidden;
    @include max-screen($tablet) {
        padding: 30px 0;
      }
    &__main {
        gap: 96px;
    }

    &__left {
        width: calc(45% - 48px);
        @include max-screen($tablet) {
            width: 100%;
          }
    }

    &__right {
        width: calc(55% - 48px);
        overflow: hidden;
        border-radius: 24px;
        @include max-screen($tablet) {
            width: 100%;
          }

        video {
            width: 100%;
            transform: scale(1.09);
        }
    }

    &__badge {
        gap: 8px;
        background-color: var(--color-gray-50);
        padding: 6px 16px;
        border-radius: 30px;
        display: inline-flex;
        backdrop-filter: blur(4px);
        box-shadow: $shadow;

        span {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-primary);
            font-family: $font-poppins;
        }

        svg {
            width: 16px;
            color: $color-yellow-dark;
            fill: $color-yellow-dark;
        }

    }

    &__content {
        margin-top: 32px;

        h1 {
            font-size: 56px;
            @include max-screen($desktop) {
                font-size: 46px;
              }
              @include max-screen($tablet) {
                font-size: 36px;
              }
              span {
                background: $brand-gradient;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            font-size: 20px;
            margin-top: 24px;
            @include max-screen($tablet) {
                font-size: 16px;
              }
        }
        ul {
            margin-top: 40px;

            @include max-screen($tablet) {
                margin-top: 16px;
            }

            li {
                font-size: 16px;
                gap: 16px;
                color: var(--color-gray-600);

                &:not(:last-child) {
                    margin-bottom: 24px;

                    @include max-screen($tablet) {
                        margin-top: 16px;
                    }
                }

                span {
                    @include round-image(32px);
                    background-color: var(--color-primary);
                    color: var(--color-white);

                    svg {
                       width: 20px;
                    }
                }
            }
        }
    }

    &__btn {
        margin-top: 28px;
        gap: 16px;
        @include max-screen($tablet) {
            margin-top: 16px;
          }
        p {
            font-size: 14px;
            margin-top: 16px;
        }
        button{
            min-width: 120px;
        }
    }
}