@import '../../../../assets/scss/variables.scss';
.follow{
&__Placeholder{
    width: 600px;
    position: relative;
    padding-right: 50px;
    @include max-screen($tablet) {
        width: 100%;
        padding-right: 0;
    }
    :global{
        .noData__button{
            @include max-screen($mobile) {
                flex-direction: column;
            }
        }
    }
    &::after{
        content: "";
        width: 1px;
        background-color: var(--color-gray);
        height: 90%;
        position: absolute;
        right: 0;
        top: 10%;
        opacity: 0.5;
        @include max-screen($tablet) {
          display: none;
        }
    }
}
&__calenderBtn{
    margin-top: 15px;
    gap: 15px;
    @include max-screen($tablet) {
       justify-content: center;
      }
    
}
&__header{
    h2{
        font-size: 20px;
    }
}
&__calender{
    width: 100%;
    padding-top: 20px;
    position: relative;
    min-height: 300px;
    @include max-screen($tablet) {
        width: 100%;
    }
    :global{
        .rdrCalendarWrapper{
            background: linear-gradient($color-white, $color-white) padding-box, $gradient8-rev border-box;
            border: 3px solid transparent;
            box-shadow: $shadow11;
            border-radius: 6px;
            width: 100%;
            max-width: 550px;
            margin: 0 auto;
        }
    }
    &.blur{
        filter: blur(4px);
        pointer-events: none;
    }
}
&__note{
    width: calc(100% - 600px);
    @include max-screen($tablet) {
        width: 100%;
        margin-top: 30px;
    }
}
}