@import '../../assets/scss/variables.scss';

.footer {

    background-color: var(--color-white);

    &.footerSpace {
        padding-top: 100px;
    }

    &__inner {
        color: var(--color-white);
        background-color: var(--color-primary);
        padding: 53px 0 60px;

        @include max-screen($tablet) {
            padding: 30px 0;
        }

        &.footerWhite {
            background-color: var(--color-white);
            color: var(--color-gray);
            padding: 32px 65px;
            border-top: 1px solid var(--color-border);

            @include max-screen($desktop-large) {
                padding: 32px 35px;
            }

            @include max-screen($tablet) {
                padding: 15px;
            }


        }
    }

    &__trial {
        background-color: var(--color-active);
        border-radius: 16px;
        padding: 64px;
        position: relative;
        z-index: 2;
        color: var(--color-text);
        gap: 50px;
        max-width: 1216px;
        margin: -146px auto 0;
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);


        @include max-screen($tablet) {
            padding: 30px;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 16px;
        }

        @include max-screen($mobile) {
            padding: 16px;
        }
    }

    &__trialButton {
        gap: 12px;
    }

    &__trialInfo {

        h2 {
            font-size: 32px;

            @include max-screen($tablet) {
                font-size: 26px;
            }

            @include max-screen($mobile) {
                font-size: 22px;
            }
        }

        p {
            font-size: 20px;
            color: var(--color-gray-600);
            margin-top: 16px;

            @include max-screen($tablet) {
                font-size: 16px;
            }

            @include max-screen($mobile) {
                font-size: 14px;
            }
        }
    }

    &__nav {
        width: 100%;
        max-width: 1216px;
        margin: 100px auto 0;

        @include max-screen($tablet) {
            margin-top: 30px;
        }

        ul {
        margin-top: 16px;

            li,
            a {
                color: var(--color-gray-200);
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background: $brand-gradient;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                @include max-screen($desktop-large) {
                    font-size: 18px;
                }

                @include max-screen($tablet) {
                    font-size: 16px;
                }
                @include max-screen($mobile) {
                    font-size: 14px;
                  }
            }
            li{
                margin: 12px 0;
            }

        }
    }
&__navCol{
    width: 33.3%;
    @include max-screen($mobile) {
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }
    h5{
        font-size: 16px;
        font-weight: 600;
        color: var(--color-gray-50);
    }
}
    &__copyright {
    margin: 100px auto 0;
        max-width: 1216px;
        @include max-screen($desktop) {
            gap: 8px;
        }
        @include max-screen($tablet) {
            padding-top: 15px;
            margin-top: 15px;
            flex-direction: column-reverse;
            align-items: center;
            gap: 16px;
            text-align: center;
        }
       
       
        p {
            font-size: 16px;
            gap: 5px;
            color: var(--color-border);

            @include max-screen($desktop) {
                font-size: 12px;
            }
            @include max-screen($mobile) {
                flex-direction: column;
              }
            a {
                color: var(--color-white);
            }

            img {
                max-width: 100px;
            }
        }

        ul {
            gap: 24px;

            @include max-screen($tablet) {
                justify-content: center;
                gap: 10px;
            }

            li {
                a {
                    color: var(--color-gray-50);

                    svg {
                        width: 22px;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    &__dashboard {
        @include max-screen($tablet) {
            flex-direction: column-reverse;
            align-items: center;
            gap: 8px;
        }

        p {
            font-size: 14px;
        }

        ul {
            gap: 20px;

            @include max-screen($tablet) {
                justify-content: center;
                gap: 5px 10px;
            }

            li,
            a {
                font-size: 14px;

                text-decoration: none;
                color: var(--color-gray);

                &:hover {
                    color: var(--color-primary);
                }
            }

        }
    }

}