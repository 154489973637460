@import '../../assets/scss/variables.scss';

.choose {
    background-color: var(--color-white);
    padding: 96px 0;
    @include max-screen($tablet) {
        padding: 30px 0;
        }
&__wrapper{
    max-width: 1152px;
    margin: 0 auto;
}
    header {
        text-align: center;
      
        margin: 0 auto 48px;
        @include max-screen($tablet) {
            margin: 0 auto 30px;
            }

        h2 {
            font-size: 36px;
            @include max-screen($tablet) {
                font-size: 26px;
                }
        }

        p {
            margin: 16px auto 0;
            font-size: 18px;
            max-width: 768px;
            @include max-screen($tablet) {
                font-size: 16px;
                }
        }
    }

    &__row {
       
        gap: 32px;
    }
    &__box{
        padding: 16px;
        border-left: 0px solid transparent;
        transition-duration: .3s;
        width: calc(50% - 16px);
        gap: 16px;
        @include max-screen($mobile) {
           width: 100%;
            }
        figure{
            width: 48px;
            height: 48px;
            min-width: 48px;
            background-color: rgba($color-primary, 0.1);
            margin-bottom: 16px;
            color: var(--color-primary);
            border-radius: 12px;
        }
        h3{
            font-size: 20px;
            margin-bottom: 4px;
            font-weight: 600;
        }
        p{
            font-size: 16px;
        }
    }

    &__text {
        text-align: center;
        margin-top: 48px;
        @include max-screen($tablet) {
            margin-top: 24px;
             }
        p {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 32px;
            font-style: italic;
            color: var(--color-primary);
            font-family: $font-poppins;
            @include max-screen($tablet) {
                font-size: 18px;
                 }
        }
    }
    &__testimonial{
        margin-top: 32px;
        background-color: var(--color-active);
        border-radius: 12px;
        padding: 32px;
        gap: 20px;
        box-shadow: $shadow12;
        @include max-screen($tablet) {
           flex-direction: column
             }
   
    figure, img{
        @include round-image(80px);
    }
    p{
        font-size: 16px;
        font-style: italic;
        font-family: $font-poppins;
        color: var(--color-gray-600);
    }
    h5{
        font-size: 16px;
        font-weight: 500;
        margin-top: 16px;
        &+p{
            font-size: 14px;
            font-style: normal;
        }
    }
    }
}