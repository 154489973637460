@import '../../assets/scss/variables.scss';

.pricing {

    &__header {
        text-align: center;
        background: $brand-bg-gradient;
        color: var(--color-white);
        padding: 96px 30px 128px;

        @include max-screen($tablet) {
            padding: 30px 15px;
        }

        h2 {
            font-size: 64px;

            @include max-screen($tablet) {
                font-size: 36px;
            }
        }

        p {
            font-size: 20px;
            margin-top: 24px;
            color: var(--color-gray-600);
        }


    }

    &__toggle {
        margin-top: 48px;
        border: 1px solid var(--color-gray-200);
        border-radius: 12px;
        padding: 6px;

        @include max-screen($tablet) {
            margin-top: 30px;
        }

        li {
            span {
                padding: 10px 18px;
                font-size: 16px;
                font-weight: 600;
                font-family: $font-poppins;
                border-radius: 8px;
                color: var(--color-primary);
                cursor: pointer;

                @include max-screen($tablet) {
                    padding: 5px 10px;
                    font-size: 14px;
                }

                &.active {
                    background: $brand-blue-gradient;
                    color: var(--color-white)
                }
            }
        }
    }

    &__Cards {
        width: 100%;
        gap: 35px;

        @include max-screen($tablet) {
            margin-bottom: 30px;
        }
    }

    &__cardsLeft {
        width: calc(40% - 18px);

        @include max-screen($tablet) {
            width: 100%;
        }
    }

    &__cardsRight {
        width: calc(60% - 18px);
        gap: 35px;

        @include max-screen($desktop) {
            gap: 30px;
        }

        @include max-screen($tablet) {
            width: 100%;
        }
    }

    &__prices {
        padding: 50px 0 0;
    }

    .cardGrayBottom {
        gap: 20px;

        header {
            background: $brand-blue-gradient;
            padding: 5px 26px;
            border-radius: 0 0 16px 0;
            display: table;
            color: var(--color-white);
            font-size: 16px;
            font-weight: 600;
        }

        h2 {
            font-size: 32px;
            font-weight: 600;
            margin-top: 20px;
            text-align: center;
            color: var(--color-blue-shade);

            @include max-screen($desktop) {
                font-size: 22px;
            }
           
        }

        article {
            border-radius: 16px;
            padding: 20px 10px;
            text-align: center;
            margin: 20px 25px 30px;
            color: var(--color-blue-shade);
            background: $brand-gradient padding-box, $brand-gradient border-box;
            border: 1px solid transparent;
            box-shadow: $shadow12;
            position: relative;
            z-index: 0;

            &::after {
                content: "";
                width: calc(100% - 1px);
                height: calc(100% - 1px);
                position: absolute;
                left: 1px;
                top: 1px;
                background: $brand-blue-gradient;
                border-radius: 16px;
                z-index: -1;
            }

            h3 {
                font-size: 55px;
                font-weight: 600;
                font-family: $font-family-base;
                line-height: 1;
                color: var(--color-white);

                @include max-screen($desktop) {
                    font-size: 40px;
                }

            }

            p {
                font-size: 20px;
                color: var(--color-white);
            }
        }

        &__left {
            width: calc(50% - 10px);
            background-color: var(--color-gray-50);
            box-shadow: $shadow12;
            border-radius: 15px;
            overflow: hidden;

            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }

    .cardGray {
        background-color: var(--color-primary);
        width: 100%;
        height: 100%;
        border-radius: 16px;
        padding: 34px 34px 20px;
        box-shadow: $shadow12;
        display: flex;
        flex-direction: column;


        header {
            gap: 32px;
            margin-bottom: 32px;
            text-align: center;

            h3 {
                margin-top: 20px;
                font-size: 36px;
                font-weight: 600;
                color: var(--color-white);

                @include max-screen($desktop) {
                    font-size: 26px;
                }
            }

            aside {
                min-width: 158px;
                border-radius: 16px;
                display: table;
                padding: 24px;
                color: var(--color-white);
                text-align: center;
                margin: 15px auto 0;
                background: $brand-gradient padding-box, $brand-gradient border-box;
                border: 1px solid transparent;
                box-shadow: 0px 0px 18px 0px $color-blue-bright;
                position: relative;
                z-index: 0;

                &::after {
                    content: "";
                    width: calc(100% - 1px);
                    height: calc(100% - 1px);
                    position: absolute;
                    left: 1px;
                    top: 1px;
                    background: $brand-blue-gradient;
                    border-radius: 16px;
                    z-index: -1;
                }




                h2 {
                    font-size: 55px;
                    font-family: $font-family-base;
                    line-height: 1;
                    font-weight: 600;
                    color: var(--color-white);

                    @include max-screen($desktop) {
                        font-size: 46px;
                    }
                }

                span {
                    font-size: 20px;

                    @include max-screen($desktop) {
                        font-size: 20px;
                    }
                }
            }

            article {
                h3 {
                    margin-top: 20px;
                    font-size: 36px;
                    font-weight: 600;
                    color: var(--color-white);

                    @include max-screen($desktop) {
                        font-size: 26px;
                    }
                }

                figure {
                    max-width: 340px;

                    img {
                        max-width: 100%;
                    }

                }
            }
        }

        h4 {
            font-size: 24px;
            font-weight: 300;
            color: var(--color-blue-shade);
        }

        ul {
            margin-top: 24px;
            flex: 1;

            li {
                gap: 12px;
                font-size: 16px;
                color: var(--color-white);

                &:not(:last-child) {
                    margin-bottom: 26px;
                }

                svg {
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        footer {
            margin-top: 20px;
        }
    }

    .pricingCard {
        border-radius: 8px;
        overflow: hidden;
        width: calc(50% - 18px);
        display: flex;
        flex-direction: column;
        background-color: var(--color-gray-50);
        box-shadow: $shadow12;

        @include max-screen($desktop) {
            width: calc(50% - 15px);
        }

        @include max-screen($mobile) {
            width: 100%;
        }

        &__header {

            header {
                background: $brand-blue-gradient;
                padding: 5px 26px;
                border-radius: 0 0 16px 0;
                display: table;
                color: var(--color-white);
                font-size: 16px;
                font-weight: 600;
            }

            h2 {
                font-size: 36px;
                font-weight: 600;
                margin-top: 20px;
                text-align: center;
                color: var(--color-blue-shade);

                @include max-screen($desktop) {
                    font-size: 22px;
                }
            }

            article {
                background: $brand-gradient padding-box, $brand-gradient border-box;
                border: 1px solid transparent;
                border-radius: 15px;
                padding: 16px 15px;
                text-align: center;
                color: var(--color-blue-shade);
                margin: 22px 45px 0;
                box-shadow: $shadow12;
                border: 1px solid transparent;
                position: relative;
                z-index: 0;

                &::after {
                    content: "";
                    width: calc(100% - 1px);
                    height: calc(100% - 1px);
                    position: absolute;
                    left: 1px;
                    top: 1px;
                    background: $brand-blue-gradient;
                    border-radius: 16px;
                    z-index: -1;
                }

                h3 {
                    font-size: 55px;
                    font-weight: 600;
                    font-family: $font-family-base;
                    line-height: 1;
                    color: var(--color-white);

                    @include max-screen($desktop) {
                        font-size: 40px;
                    }
                }

                p {
                    font-size: 20px;
                    font-weight: 300;
                    color: var(--color-white);

                }
            }

            h5 {
                font-size: 20px;
                font-weight: 500;
            }

            p {
                margin: 6px 0;
                font-size: 16px;
                font-weight: 300;

            }


        }

        &__info {
            padding: 34px 34px 0;


            ul {
                display: flex;
                flex-direction: column;
                gap: 16px;

                li {
                    font-size: 16px;
                    gap: 14px;
                    color: var(--color-gray-600);

                    svg {
                        width: 22px;
                        height: 22px;
                        min-width: 22px;
                    }

                    @include max-screen($tablet) {
                        font-size: 14px;
                    }


                }
            }
        }

        &__btn {
            padding: 20px;
        }
    }

    &__CardsSkip {
        margin-top: 80px;
        gap: 62px;
        @include max-screen($desktop) {
            margin-top: 40px;
            gap: 30px;
        }
    }

    &__cardsSkipLeft {
        width: calc(50% - 31px);
        @include max-screen($desktop) {
            width: 100%;
        }
    }

    &__cardsSkipRight {
        width: calc(50% - 31px);
        @include max-screen($desktop) {
            width: 100%;
        }
        h2 {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            width: 100%;
        }

        p {
            margin-top: 25px;
            font-size: 20px;
            color: var(--color-primary);
        }
    }

    &__cardsSkipBtn {
        width: 100%;
        margin-top: 30px;

        button {
            min-width: 142px;
        }
    }
    sup{
        font-size: 24px;
    }
}